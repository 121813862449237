import { Color } from "src/app/types/types";

export const getResultComment = (result: Color | "draw" | undefined | "bye") => {
  switch (result) {
    case "draw":
      return "1/2-1/2";
    case Color.WHITE:
      return "1-0";
    case Color.BLACK:
      return "0-1";
    default:
      return "";
  }
};
