import { useEffect, useRef, useState } from "react";
import { TournamentProps } from "./Tournament.types";
import { useLocation, useNavigate } from "react-router-dom";
import { ChessGame } from "src/app/components/ChessGame/ChessGame";
import { SidePanel } from "src/app/components/SidePanel/SidePanel";
import { usePlayerId } from "src/app/hooks/usePlayerId/usePlayerId";
import { useAppDispatch, useAppSelector } from "src/app/redux/store";
import {
  resetGameState,
  setIsFirstMovePlayed,
  setIsGameFinished,
  setPlayers,
  setRoom,
} from "src/app/redux/features/GameSlice/GameSlice";
import { Color, SocketEvent } from "src/app/types/types";
import { setConnectedPlayers } from "src/app/redux/features/PlayersSlice/PlayersSlice";
import { usePlayer } from "src/app/hooks/usePlayer/usePlayer";
import { Player } from "src/app/context/playerContextController/PlayerContextController.types";
import { AppRoute } from "src/routing/AppRoute.enum";
import { ChessLoader } from "src/app/components/Loaders/ChessLoader";
import { toast } from "react-toastify";
import {
  setBlackTime,
  setWhiteTime,
} from "src/app/redux/features/TimerSlice/TimerSlice";
import { formatTime } from "src/app/utils/formatTime";
import { Notation } from "src/app/components/Notation/Notation";
import { cn } from "src/lib/utils";

export const Tournament = ({ socket }: TournamentProps) => {
  const [isGameLoading, setIsGameLoading] = useState<boolean>(true);
  const [isFinishGameModalOpen, setIsFinishGameModalOpen] = useState(false);
  const [squareHeight, setSquareHeight] = useState(0);

  const squareRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const { player, updatePlayerStatus, resetPlayer, removeUsername } =
    usePlayer();
  const { setStoredPlayerId } = usePlayerId();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const room = useAppSelector((state) => state.game.room);
  const players = useAppSelector((state) => state.game.players);
  const moves = useAppSelector((state) => state.game.moves);
  const initialTimeInSeconds = useAppSelector(
    (state) => state.time.initialTimeInSeconds
  );

  const isPlayerWhite =
    players[1] === player.username ? Color.BLACK : Color.WHITE;

  useEffect(() => {
    socket.on(SocketEvent.PLAYER_JOIN, (playersUsernames: string[]) => {
      try {
        if (playersUsernames.length < 2) {
          dispatch(setPlayers([...playersUsernames, "Waiting..."]));
        } else {
          dispatch(setPlayers(playersUsernames));
        }
      } catch (error) {
        console.error(error);
      }
    });

    socket.on(SocketEvent.SET_GAME, async (game: string) => {
      setIsFinishGameModalOpen(false);
      await dispatch(setRoom(undefined));
      dispatch(setBlackTime(formatTime(initialTimeInSeconds)));
      dispatch(setWhiteTime(formatTime(initialTimeInSeconds)));
      dispatch(setPlayers([]));
      dispatch(setIsFirstMovePlayed(false));
      dispatch(setRoom(game));
    });

    socket.on(SocketEvent.USER_ID, (id: string, callback: Function) => {
      setStoredPlayerId(id);
      callback(false);
    });

    socket.on(SocketEvent.USERS_LIST_UPDATE, (users) => {
      dispatch(setConnectedPlayers(users));
      users.forEach((user: Player) => {
        if (user.username === player.username) {
          updatePlayerStatus(user.status);
        }
      });
    });

    socket.on(SocketEvent.RECEIVED_BYE, () => {
      dispatch(
        setIsGameFinished({
          finished: true,
          result: "bye",
          reason: undefined,
        })
      );
      setIsFinishGameModalOpen(true);
    });

    if (player.username) {
      socket.emit(
        SocketEvent.CHECK_IS_PLAYER_IN_TOURNAMENT,
        player.username,
        (isPlayerInTournament: boolean) => {
          if (isPlayerInTournament) {
            socket.emit(SocketEvent.USER_RECONNECT, player.username);
          } else {
            resetPlayer();
            removeUsername();
            navigate(AppRoute.home);
          }
        }
      );
    }

    dispatch(resetGameState());
    setIsFinishGameModalOpen(false);

    return () => {
      socket.off(SocketEvent.PLAYER_JOIN);
      socket.off(SocketEvent.SET_GAME);
      socket.off(SocketEvent.USER_ID);
      socket.off(SocketEvent.USERS_LIST_UPDATE);
      socket.off(SocketEvent.RECEIVED_BYE);
    };
  }, []);

  useEffect(() => {
    socket.on(
      SocketEvent.FINISH_TOURNAMENT,
      ({
        players,
        tournamentName,
      }: {
        players: Player[];
        tournamentName: string;
      }) => {
        socket.emit(SocketEvent.GAME_END, {
          result: "",
          reason: "finish-tournament",
          room,
        });

        const finishTournament = setTimeout(() => {
          dispatch(setConnectedPlayers([]));
          resetPlayer();
          removeUsername();
          navigate(pathname + "/tournamentend", {
            state: { players, tournamentName },
          });
        }, 5500);

        toast(
          "Tournament has been finished. You're going to be redirected to scoreboard.",
          {
            onClose: () => {
              clearTimeout(finishTournament);
              dispatch(setConnectedPlayers([]));
              resetPlayer();
              navigate(pathname + "/tournamentend", {
                state: { players, tournamentName },
              });
            },
          }
        );
      }
    );

    return () => {
      socket.off(SocketEvent.FINISH_TOURNAMENT);
    };
  }, [room]);

  useEffect(() => {
    if (!player.username) {
      navigate(pathname + "/login");
    } else {
      socket.emit(
        SocketEvent.CHECK_TOURNAMENT,
        player.username,
        ({
          active,
          player,
          tournament,
        }: {
          active: boolean;
          player: boolean;
          tournament: boolean;
        }) => {
          if (tournament) {
            if (!active) {
              if (!player) {
                resetPlayer();
                navigate(pathname + "/login");
              } else {
                navigate(pathname + "/waiting");
              }
            } else {
              if (!player) {
                navigate(AppRoute.home);
              }
            }
          } else {
            navigate(AppRoute.home);
          }
        }
      );
    }

    socket.emit(
      SocketEvent.CHECK_PLAYER,
      player.username,
      (isPlayerActive: boolean) => {
        if (!isPlayerActive) {
          resetPlayer();
          navigate(AppRoute.home);
        } else {
          setIsGameLoading(false);
        }
      }
    );
  }, [player.username]);

  useEffect(() => {
    const updateSquareHeight = () => {
      if (squareRef.current) {
        setSquareHeight(squareRef.current.clientHeight);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateSquareHeight();
    });

    if (squareRef.current) {
      resizeObserver.observe(squareRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className="w-11/12 md:w-3/4 lg:w-1/2 h-[90%] flex flex-col">
      <div className="h-1/6 flex items-center">
        {!isGameLoading && <Notation moves={moves} />}
      </div>
      <div className="h-5/6 flex items-start">
        <div
          className="w-full max-w-[80%] max-h-full flex justify-start aspect-square"
          ref={squareRef}
        >
          {isGameLoading && (
            <div className="absolute h-1/2 w-1/2 flex justify-center items-center">
              <ChessLoader />
            </div>
          )}

          <div
            className={cn(
              "h-full aspect-square",
              isGameLoading ? "opacity-0" : "opacity-100"
            )}
          >
            <ChessGame
              socket={socket}
              isPlayerWhite={isPlayerWhite}
              setIsFinishGameModalOpen={setIsFinishGameModalOpen}
              isFinishGameModalOpen={isFinishGameModalOpen}
            />
          </div>
        </div>
        {!isGameLoading && (
          <div
            className={cn("w-[20%] flex justify-center")}
            style={{ height: squareHeight }}
          >
            <SidePanel
              socket={socket}
              game={room}
              isPlayerWhite={isPlayerWhite}
            />
          </div>
        )}
      </div>
    </div>
  );
};
