import { DefaultEventsMap } from "@socket.io/component-emitter";
import { Socket } from "socket.io-client";
import { Color } from "../../types/types";

export type TournamentProps = {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
};

export enum GameFinishReason {
  time = "opponent time",
  checkmate = "checkmate",
  surrender = "opponent surrender",
  threefold = "threefold repetition",
  stalemate = "stalemate",
  material = "insufficient material",
  tournamentFinish = "finish tournament",
  opponentDisconnect = "opponent disconnect",
  drawAgreement = "players agreement",
  firstMove = "first move",
}

export type GameResult =
  | {
      finished: boolean;
      result?: Color | "draw" | "bye";
      reason?: GameFinishReason;
    }
  | undefined;
