import { Button } from "src/components/ui/button";
import { Logo } from "src/app/common/Logo/Logo";
import { HomeProps } from "./Home.types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "src/routing/AppRoute.enum";
import { usePlayerId } from "src/app/hooks/usePlayerId/usePlayerId";
import { usePlayer } from "src/app/hooks/usePlayer/usePlayer";
import { SocketEvent } from "src/app/types/types";
import chessPenguins from "src/app/common/gifs/penguin-check.gif";
import { WhyAccordion } from "src/app/components/WhyAccordion/WhyAccordion";
import { useAppDispatch } from "src/app/redux/store";
import { removeTimeFromSession } from "src/app/redux/features/TimerSlice/TimerSlice";

export const Home = ({ socket }: HomeProps) => {
  const navigate = useNavigate();
  const { setStoredPlayerId } = usePlayerId();
  const { removeUsername } = usePlayer();

  const handleCreateNewTournament = () => {
    navigate(AppRoute.create);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    socket.on(SocketEvent.USER_ID, (id: string, callback: Function) => {
      setStoredPlayerId(id);
      callback(false);
    });

    removeUsername();
    dispatch(removeTimeFromSession());

    return () => {
      socket.off(SocketEvent.USER_ID);
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col py-10 md:p-10 items-center md:w-[80%]">
      <Logo />
      <div className="text-xl md:text-2xl mt-14 font-publica text-center md:text-left">
        <div>• Create chess tournaments quickly ♟️</div>
        <div>• No registration required ✔️</div>
        <div>• Completely free to play 💸</div>
      </div>
      <Button
        type="button"
        className="w-80 md:w-96 bg-blue-100  hover:bg-blue-300 mt-10"
        onClick={handleCreateNewTournament}
      >
        Create new tournament
      </Button>
      <img src={chessPenguins} alt="chess-gif" className="w-64" />
      <WhyAccordion />
    </div>
  );
};
