import { useContext } from "react";

import { PlayerContext } from "src/app/context/playerContext/PlayerContext";

export const usePlayer = () => {
  const context = useContext(PlayerContext);
  if (context === undefined) {
    throw new Error("PlayerContext must be within PlayerProvider");
  }

  return context;
};
