export enum AppRoute {
  home = "/",
  create = "/create",
  login = "/:id/login",
  tournament = "/:id",
  waitingRoom = "/:id/waiting",
  tournamentEnd = "/:id/tournamentend",
  managerPanel = "/manager",
  managerAuth = "/auth",
}
