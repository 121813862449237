import { createContext } from "react";
import { PlayerContextType } from "./PlayerContext.types";
import { PlayerStatus } from "src/app/components/TableSheet/Tablesheet.types";

export const defaultValue: PlayerContextType = {
  player: {
    id: null,
    username: sessionStorage.getItem("username"),
    points: 0,
    status: PlayerStatus.NOT_STARTED,
    isAdmin: false,
    isSpectator: false,
    isDeleted: false,
  },
  updateId: () => {},
  updateUsername: () => {},
  updatePlayerInfo: () => {},
  removeUsername: () => {},
  updatePlayerStatus: () => {},
  setIsSpectator: () => {},
  resetPlayer: () => {},
};

export const PlayerContext = createContext<PlayerContextType>(defaultValue);
