import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClockTime, TimerSliceState } from "./TimerSlice.types";
import { formatTime } from "src/app/utils/formatTime";

const initialTimeInSeconds = Number(sessionStorage.getItem("time")) * 60;

const initialState: TimerSliceState = {
  initialTimeInSeconds: initialTimeInSeconds,
  initialTime: [initialTimeInSeconds, initialTimeInSeconds],
  startTimer: false,
  whiteTime: formatTime(initialTimeInSeconds),
  blackTime: formatTime(initialTimeInSeconds),
};

export const TimerSlice = createSlice({
  name: "TimerSlice",
  initialState,
  reducers: {
    setInitialTime: (state, action: PayloadAction<number[]>) => {
      state.initialTime = action.payload;
    },
    setStartTimer: (state, action: PayloadAction<boolean>) => {
      state.startTimer = action.payload;
    },
    setWhiteTime: (state, action: PayloadAction<ClockTime>) => {
      state.whiteTime = action.payload;
    },
    setBlackTime: (state, action: PayloadAction<ClockTime>) => {
      state.blackTime = action.payload;
    },
    removeTimeFromSession: () => {
      sessionStorage.removeItem("time");
    },
  },
});

export default TimerSlice.reducer;
export const {
  setInitialTime,
  setStartTimer,
  setWhiteTime,
  setBlackTime,
  removeTimeFromSession,
} = TimerSlice.actions;
