import { BrowserRouter as Router } from "react-router-dom";
import { AppProvidersProps } from "./AppProviders.types";
import { PlayerContextController } from "../context/playerContextController/PlayerContextController";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const AppProviders = ({ children }: AppProvidersProps) => (
  <HelmetProvider>
    <Helmet>
      <title>Gambited</title>
      <meta
        name="description"
        content="• Create chess tournaments quickly ♟️ • No registration required ✔️ • Completely free to play 💸"
      />
    </Helmet>
    <PlayerContextController>
      <Provider store={store}>
        <Router>{children}</Router>
      </Provider>
    </PlayerContextController>
  </HelmetProvider>
);
