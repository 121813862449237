import { cn } from "src/lib/utils";
import { LogoProps } from "./Logo.types";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "src/routing/AppRoute.enum";

export const Logo = ({ redirectToHome }: LogoProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center">
      <button
        className={cn(
          "text-5xl font-publica font-bold",
          !redirectToHome && "pointer-events-none"
        )}
        onClick={() => {
          if (redirectToHome) {
            navigate(AppRoute.home);
          }
        }}
      >
        gambited<span className="text-2xl font-normal">.com</span>
      </button>
    </div>
  );
};
