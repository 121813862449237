import { configureStore } from "@reduxjs/toolkit";
import { GameSlice } from "./features/GameSlice/GameSlice";
import { TimerSlice } from "./features/TimerSlice/TimerSlice";
import { PlayersSlice } from "./features/PlayersSlice/PlayersSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const store = configureStore({
  reducer: {
    game: GameSlice.reducer,
    time: TimerSlice.reducer,
    players: PlayersSlice.reducer,
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
