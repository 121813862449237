import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";

export const WhyAccordion = () => {
  return (
    <Accordion
      type="single"
      collapsible
      className="md:min-w-[384px] mt-6 max-w-[500px]"
    >
      <AccordionItem value="item-1 border-none">
        <AccordionTrigger className="p-2 px-5 text-sm">
          <div>
            Why was{" "}
            <span className="font-publica">
              <span className="text-xl font-bold">gambited</span>.com
            </span>{" "}
            created?
          </div>
        </AccordionTrigger>
        <AccordionContent className="w-80 md:w-auto">
          <span className="font-publica">
            <span className="text-xl font-bold">gambited</span>.com
          </span>{" "}
          was developed to provide a quick and easy way to create chess
          tournaments without requiring registration.
          <br />
          <br />
          Do you want to beat your friends at chess, but they don't want to
          create an account? Well, now they have no excuse! Just send them the
          link and beat their ass.
          <br />
          <br />
          Maybe you're waiting in a queue or stuck in a boring work meeting?
          Play a quick chess tournament together!
          <br />
          <br />
          If you have any advice or just want to say hi, feel free to contact me
          at{" "}
          <span className="font-bold hover:text-blue-400">
            <a href="mailto:hello@gambited.com">hello@gambited.com</a>
          </span>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
