import { Clock4 } from "lucide-react";
import { ClockProps } from "./Clock.types";

export const Clock = ({ time, isWhiteTurn }: ClockProps) => {
  return !time.minutes && !time.seconds ? (
    <div
      className={
        "bg-grey-200 h-8 w-12 md:w-20 md:h-12 rounded flex justify-center items-center"
      }
    >
      <Clock4 className="w-4 md:w-auto" />
    </div>
  ) : (
    <div
      className={`border-2 text-center md:w-20  text-xs md:text-lg
      ${isWhiteTurn ? "font-bold" : "border-transparent"}
      ${
        time.minutes === 0 && time.seconds < 20
          ? "bg-danger"
          : time.minutes === 0
            ? "bg-attention"
            : "bg-grey-200"
      } p-2 md:p-2 rounded`}
    >
      {`${String(time.minutes).padStart(2, "0")}:${String(
        time.seconds
      ).padStart(2, "0")}`}
    </div>
  );
};
