import { useEffect, useState } from "react";
import { CountdownProps } from "./Countdown.types";
import { useAppSelector } from "src/app/redux/store";
import { INITIAL_SECONDS_TO_START } from "src/app/constants/constants";
import { Color, SocketEvent } from "src/app/types/types";
import { usePlayer } from "src/app/hooks/usePlayer/usePlayer";
import { GameFinishReason } from "src/app/pages/tournament/Tournament.types";

export const Countdown = ({ socket, isPlayerWhite }: CountdownProps) => {
  const [countdownSeconds, setCountdownSeconds] = useState(
    INITIAL_SECONDS_TO_START
  );

  const { player } = usePlayer();

  const room = useAppSelector((state) => state.game.room);

  useEffect(() => {
    socket.on(SocketEvent.UPDATE_COUNTDOWN, (seconds) => {
      if (seconds > 0) {
        setCountdownSeconds(seconds);
      } else {
        setCountdownSeconds(0);
        socket.emit(SocketEvent.GAME_END, { result: "", reason: GameFinishReason.firstMove, room });
      }
    });

    return () => {
      socket.off(SocketEvent.UPDATE_COUNTDOWN);
    };
  }, []);

  return (
    <div className="max-w-24 text-center">
      {player.isSpectator
        ? "White has "
        : isPlayerWhite === Color.WHITE
          ? "You have "
          : "Your opponent has "}
      <span className="text-danger">{countdownSeconds}</span> seconds to start
      the game.
    </div>
  );
};
