import { useState } from "react";

export const usePlayerId = () => {
  const [playerId, setPlayerId] = useState<string | null>(
    sessionStorage.getItem("playerId"),
  );

  const setStoredPlayerId = (newPlayerId: string) => {
    sessionStorage.setItem("playerId", newPlayerId);
    setPlayerId(newPlayerId);
  };

  return { playerId, setStoredPlayerId };
};
