import { useEffect, useState } from "react";
import { Clock } from "../Clock/Clock";
import { SidePanelProps } from "./SidePanel.types";
import { formatTime } from "src/app/utils/formatTime";
import { Check, Flag, X } from "lucide-react";
import { cn } from "src/lib/utils";
import { useAppDispatch, useAppSelector } from "src/app/redux/store";
import { GameFinishReason } from "src/app/pages/tournament/Tournament.types";
import {
  setBlackTime,
  setWhiteTime,
} from "src/app/redux/features/TimerSlice/TimerSlice";
import { Countdown } from "../Countdown/Countdown";
import {
  Color,
  SocketEvent,
  Tournament,
  TournamentType,
} from "src/app/types/types";
import { usePlayer } from "src/app/hooks/usePlayer/usePlayer";
import {
  resetGameState,
  setIsDrawOffered,
  setPlayers,
  setRoom,
} from "src/app/redux/features/GameSlice/GameSlice";
import { Button } from "src/components/ui/button";
import { PlayerDisplay } from "./PlayerDisplay/PlayerDisplay";
import { ChessLoader } from "../Loaders/ChessLoader";
import { toast } from "react-toastify";

export const SidePanel = ({ socket, isPlayerWhite }: SidePanelProps) => {
  const initialTimeInSeconds = useAppSelector(
    (state) => state.time.initialTimeInSeconds
  );
  const [initialTime, setInitialTime] = useState<number[]>([
    initialTimeInSeconds,
    initialTimeInSeconds,
  ]);
  const [isSurrendering, setIsSurrendering] = useState(false);
  const [isOfferDraw, setIsOfferDraw] = useState(false);
  const [isOfferDrawConfirmed, setIsOfferDrawConfirmed] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [tournamentInfo, setTournamentInfo] = useState<Tournament>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { player, setIsSpectator } = usePlayer();
  const dispatch = useAppDispatch();
  const isWhiteTurn = useAppSelector((state) => state.game.isWhiteTurn);
  const isFirstMovePlayed = useAppSelector(
    (state) => state.game.isFirstMovePlayed
  );
  const isDrawOffered = useAppSelector((state) => state.game.isDrawOffered);

  const room = useAppSelector((state) => state.game.room);
  const isGameFinished = useAppSelector((state) => state.game.isGameFinished);
  const players = useAppSelector((state) => state.game.players);
  const whiteTime = useAppSelector((state) => state.time.whiteTime);
  const blackTime = useAppSelector((state) => state.time.blackTime);

  const isGameStarted = startTimer || isFirstMovePlayed;

  const handleSurrendering = () => {
    setIsSurrendering(true);
  };

  const handleConfirmSurrender = () => {
    socket.emit(SocketEvent.GAME_END, {
      result: isPlayerWhite === Color.WHITE ? Color.BLACK : Color.WHITE,
      reason: GameFinishReason.surrender,
      room: room,
    });
    setIsSurrendering(false);
  };

  const handleConfirmDrawOffer = () => {
    const playerUsername = player.username;
    socket.emit(SocketEvent.DRAW_OFFER, { playerUsername, room });
    setIsOfferDraw(false);
    setIsOfferDrawConfirmed(true);
  };

  const handleAcceptDraw = () => {
    socket.emit(SocketEvent.GAME_END, {
      result: "draw",
      reason: GameFinishReason.drawAgreement,
      room: room,
    });
    setIsDrawOffered(false);
  };

  const handleDeclineDraw = () => {
    socket.emit(SocketEvent.DECLINE_DRAW, room);
  };

  const handleStopSpectating = () => {
    dispatch(resetGameState());
    setIsSpectator(false);
    dispatch(setWhiteTime(formatTime(initialTimeInSeconds)));
    dispatch(setBlackTime(formatTime(initialTimeInSeconds)));
    socket.emit(SocketEvent.STOP_SPECTATING, player.username);
  };

  const handleCancelGameSearch = () => {
    socket.emit(SocketEvent.CANCEL_GAME_SEARCH, room);
    dispatch(setPlayers([]));
    dispatch(setRoom(undefined));
  };

  const joinGame = () => {
    socket.emit(SocketEvent.JOIN_GAME, player.username);
  };

  useEffect(() => {
    if (isGameStarted) {
      socket.on(SocketEvent.UPDATE_CLOCK, (clocks) => {
        if (clocks.every((clock: number) => clock > 0)) {
          dispatch(setWhiteTime(formatTime(clocks[0])));
          dispatch(setBlackTime(formatTime(clocks[1])));
        } else {
          if (clocks[0] <= 0) {
            socket.emit(SocketEvent.GAME_END, {
              result: Color.BLACK,
              reason: "timeout",
              room,
            });
          } else if (clocks[1] <= 0) {
            socket.emit(SocketEvent.GAME_END, {
              result: Color.WHITE,
              reason: "timeout",
              room,
            });
          }
        }
      });
    }

    socket.on(SocketEvent.RECOVER_GAME, ({ clocks }) => {
      dispatch(setIsDrawOffered(false));
      setIsOfferDrawConfirmed(false);
      setInitialTime(clocks);
      setStartTimer(true);
    });

    socket.on(SocketEvent.OFFER_DRAW, () => {
      dispatch(setIsDrawOffered(true));
    });

    socket.on(SocketEvent.DRAW_DECLINED, () => {
      toast("Draw declined.");
      setIsOfferDrawConfirmed(false);
      dispatch(setIsDrawOffered(false));
    });

    if (isGameFinished?.finished) {
      setIsOfferDrawConfirmed(false);
    }

    return () => {
      socket.off(SocketEvent.UPDATE_CLOCK);
      socket.off(SocketEvent.RECOVER_GAME);
      socket.off(SocketEvent.OFFER_DRAW);
      socket.off(SocketEvent.DRAW_DECLINED);
    };
  }, [
    isWhiteTurn,
    whiteTime,
    blackTime,
    startTimer,
    isGameStarted,
    isGameFinished,
  ]);

  useEffect(() => {
    setBlackTime(formatTime(initialTime[1]));
    setWhiteTime(formatTime(initialTime[0]));
  }, [initialTime]);

  useEffect(() => {
    socket.emit(
      SocketEvent.GET_TOURNAMENT_INFO,
      player.username,
      (tournament: Tournament) => {
        setTournamentInfo(tournament);
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <div
      className={cn(
        "h-full w-full md:w-auto flex justify-between items-center",
        isPlayerWhite === Color.WHITE ? "flex-col" : "flex-col-reverse"
      )}
    >
      <div
        className={cn(
          "flex items-center w-full",
          isPlayerWhite === Color.WHITE ? "flex-col" : "flex-col-reverse"
        )}
      >
        <Clock time={blackTime} isWhiteTurn={!isWhiteTurn} />
        <PlayerDisplay
          playerToDisplay={players.length > 0 ? players[1] : "Player2"}
        />
      </div>

      {isLoading ? (
        <ChessLoader />
      ) : room ? (
        <>
          {!isFirstMovePlayed && !players.includes("Waiting...") && (
            <Countdown socket={socket} isPlayerWhite={isPlayerWhite} />
          )}
          {!player.isSpectator &&
            isFirstMovePlayed &&
            !isSurrendering &&
            !isOfferDraw &&
            !isOfferDrawConfirmed &&
            !isDrawOffered && (
              <div className="w-16 md:w-20 h-24 flex flex-row justify-around">
                <button
                  disabled={!isGameStarted}
                  onClick={handleSurrendering}
                  className="flex justify-center items-center"
                >
                  <Flag
                    className={
                      isGameStarted ? "hover:text-danger cursor-pointer" : ""
                    }
                  />
                </button>
                <button
                  disabled={!isGameStarted}
                  onClick={() => setIsOfferDraw(true)}
                >
                  <span
                    className={cn(
                      "text-3xl text-center",
                      isGameStarted ? "hover:text-orange cursor-pointer" : ""
                    )}
                  >
                    ½
                  </span>
                </button>
              </div>
            )}
          {player.isSpectator && isFirstMovePlayed && (
            <Button
              type="button"
              className="max-w-24 w-full bg-red-500 hover:bg-red-700 break-words md:h-14 text-xs md:text-sm"
              onClick={handleStopSpectating}
            >
              Stop <br />
              spectating
            </Button>
          )}
          {isSurrendering && (
            <div className="w-16 md:w-auto">
              <div className="text-sm mb-2 text-center">Surrender?</div>
              <div className="flex justify-center">
                <button
                  className="border-2 border-grey-200 md:p-2 rounded-tl rounded-bl hover:bg-danger cursor-pointer"
                  onClick={handleConfirmSurrender}
                >
                  <Check />
                </button>
                <button
                  className="border-2 border-grey-200 md:p-2 rounded-tr rounded-br hover:bg-grey-200 cursor-pointer"
                  onClick={() => setIsSurrendering(false)}
                >
                  <X />
                </button>
              </div>
            </div>
          )}
          {isOfferDraw && (
            <div className="w-16 md:w-auto">
              <div className="text-sm mb-2 text-center">Offer draw?</div>
              <div className="flex justify-center">
                <button
                  className="border-2 border-grey-200 md:p-2 rounded-tl rounded-bl hover:bg-orange cursor-pointer"
                  onClick={handleConfirmDrawOffer}
                >
                  <Check />
                </button>
                <button
                  className="border-2 border-grey-200 md:p-2 rounded-tr rounded-br hover:bg-grey-200 cursor-pointer"
                  onClick={() => setIsOfferDraw(false)}
                >
                  <X />
                </button>
              </div>
            </div>
          )}
          {isOfferDrawConfirmed && (
            <div className="text-sm text-center max-w-24">
              Draw offer has been sent...
            </div>
          )}
          {isDrawOffered && (
            <div className="flex flex-col justify-center items-center max-w-24">
              <div className="text-sm mb-2 text-center">
                Opponent has offered a draw. Do you accept?
              </div>
              <div className="flex">
                <button
                  className="border-2 border-grey-200 p-2 rounded-tl rounded-bl hover:bg-orange cursor-pointer"
                  onClick={handleAcceptDraw}
                >
                  <Check />
                </button>
                <button
                  className="border-2 border-grey-200 p-2 rounded-tr rounded-br hover:bg-grey-200 cursor-pointer"
                  onClick={handleDeclineDraw}
                >
                  <X />
                </button>
              </div>
            </div>
          )}
          {!isFirstMovePlayed && players.includes("Waiting...") && (
            <Button
              className="w-4/5 md:w-24 bg-red-500 hover:bg-red-700"
              onClick={handleCancelGameSearch}
            >
              Cancel
            </Button>
          )}
        </>
      ) : tournamentInfo?.type === TournamentType.SWISS ? (
        <></>
      ) : (
        <Button
          className="w-4/5 md:w-24 bg-green-100 hover:bg-green-200 text-white"
          onClick={joinGame}
        >
          Ready
        </Button>
      )}

      <div
        className={cn(
          "flex items-center w-full",
          isPlayerWhite === Color.WHITE ? "flex-col" : "flex-col-reverse"
        )}
      >
        <PlayerDisplay
          playerToDisplay={players.length > 0 ? players[0] : "Player1"}
          white
        />
        <Clock time={whiteTime} isWhiteTurn={isWhiteTurn} />
      </div>
    </div>
  );
};
