import { GameResult } from "src/app/pages/tournament/Tournament.types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GameSliceState } from "./GameSlice.types";
import { INITIAL_FEN } from "src/app/constants/constants";

const initialState: GameSliceState = {
  room: undefined,
  players: [],
  spectators: [],
  isWhiteTurn: true,
  isFirstMovePlayed: false,
  isDrawOffered: false,
  isGameFinished: {
    finished: false,
    result: undefined,
    reason: undefined,
  },
  position: INITIAL_FEN,
  moves: [],
  pgn: "",
};

export const GameSlice = createSlice({
  name: "GameSlice",
  initialState,
  reducers: {
    setRoom: (state, action: PayloadAction<string | undefined>) => {
      state.room = action.payload;
    },
    setPlayers: (state, action: PayloadAction<string[]>) => {
      state.players = action.payload;
    },
    setSpectator: (state, action: PayloadAction<string>) => {
      state.spectators.push(action.payload);
    },
    removeSpectator: (state, action: PayloadAction<string>) => {
      state.spectators.filter((spectator) => spectator !== action.payload);
    },
    setIsWhiteTurn: (state, action: PayloadAction<boolean>) => {
      state.isWhiteTurn = action.payload;
    },
    setIsFirstMovePlayed: (state, action: PayloadAction<boolean>) => {
      state.isFirstMovePlayed = action.payload;
    },
    setIsDrawOffered: (state, action: PayloadAction<boolean>) => {
      state.isDrawOffered = action.payload;
    },
    setIsGameFinished: (state, action: PayloadAction<GameResult>) => {
      state.isGameFinished = action.payload;
    },
    setMoves: (state, action: PayloadAction<string[]>) => {
      state.moves = action.payload;
    },
    setPgn: (state, action: PayloadAction<string>) => {
      state.pgn = action.payload;
    },
    resetPosition: (state) => {
      state.position = INITIAL_FEN;
    },
    resetGameState: (state) => {
      state.isDrawOffered = initialState.isDrawOffered;
      state.isFirstMovePlayed = initialState.isFirstMovePlayed;
      state.isGameFinished = initialState.isGameFinished;
      state.isWhiteTurn = initialState.isWhiteTurn;
      state.players = initialState.players;
      state.position = initialState.position;
      state.room = initialState.room;
      state.spectators = initialState.spectators;
      state.moves = initialState.moves;
      state.pgn = initialState.pgn;
    },
  },
});

export default GameSlice.reducer;
export const {
  setRoom,
  setPlayers,
  setIsWhiteTurn,
  setIsFirstMovePlayed,
  setIsDrawOffered,
  setIsGameFinished,
  setSpectator,
  setMoves,
  removeSpectator,
  resetPosition,
  resetGameState,
  setPgn,
} = GameSlice.actions;
