import { useEffect, useState } from "react";
import { ManagerPanelProps } from "./ManagerPanel.types";
import { Game, SocketEvent, Tournament } from "src/app/types/types";
import { usePlayerId } from "src/app/hooks/usePlayerId/usePlayerId";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { Copy, Loader2 } from "lucide-react";
import { useAuth0 } from "@auth0/auth0-react";
import { ChessLoader } from "src/app/components/Loaders/ChessLoader";
import { convertPlayerStatus } from "src/app/utils/convertPlayerStatus";
import { Player } from "src/app/context/playerContextController/PlayerContextController.types";

export const ManagerPanel = ({ socket }: ManagerPanelProps) => {
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [activePlayers, setActivePlayers] = useState<Player[]>([]);
  const [allPlayers, setAllPlayers] = useState<Player[]>([]);
  const [games, setGames] = useState<[string, Game][]>([]);
  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { isAuthenticated, isLoading: isAuthorizing } = useAuth0();
  const { setStoredPlayerId } = usePlayerId();

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const deleteTournament = (tournamentId: string) => {
    socket.emit(SocketEvent.DELETE_TOURNAMENT, tournamentId);
  };

  const getTournaments = () => {
    setIsLoading(true);
    try {
      socket.emit(
        SocketEvent.GET_TOURNAMENTS,
        ({
          tournaments,
          activePlayers,
          allPlayers,
          games,
        }: {
          tournaments: Tournament[];
          activePlayers: Player[];
          allPlayers: Player[];
          games: [string, Game][];
        }) => {
          setTournaments(tournaments);
          setActivePlayers(activePlayers);
          setAllPlayers(allPlayers);
          setGames(games);
        }
      );
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTournaments();

    socket.on(SocketEvent.USER_ID, (id: string, callback: Function) => {
      setStoredPlayerId(id);
      callback(true);
    });

    socket.on(
      SocketEvent.UPDATE_TOURNAMENTS,
      ({
        tournaments,
        activePlayers,
        allPlayers,
        games,
      }: {
        tournaments: Tournament[];
        activePlayers: Player[];
        allPlayers: Player[];
        games: [string, Game][];
      }) => {
        setTournaments(tournaments);
        setActivePlayers(activePlayers);
        setAllPlayers(allPlayers);
        setGames(games);
      }
    );

    return () => {
      socket.off(SocketEvent.USER_ID);
      socket.off(SocketEvent.UPDATE_TOURNAMENTS);
    };
  }, []);

  return (
    <div className="flex w-full">
      {isAuthorizing ? (
        <ChessLoader />
      ) : (
        (isAuthenticated || process.env.REACT_APP_MODE === "dev") && (
          <div className="w-full text-center font-bold">
            <div className="flex justify-center">
              <div className="flex flex-col  items-center">
                <span>Active Players</span>{" "}
                <div className="h-32 w-64 m-4 border overflow-scroll">
                  <ul>
                    {!!activePlayers.length &&
                      activePlayers.map((p, i) => {
                        return (
                          <li
                            key={i}
                            className="flex justify-between w-full p-4 border-b"
                          >
                            {i}. {p.username}
                            <Button className="bg-red-500 hover:bg-red-700">
                              Delete
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <span>All Players</span>{" "}
                <div className="h-32 w-64 m-4 border overflow-scroll">
                  <ul>
                    {!!allPlayers.length &&
                      allPlayers.map((p, i) => {
                        return (
                          <li
                            key={i}
                            className="flex justify-between w-full p-4 border-b"
                          >
                            {i}. {p.username}
                            <Button className="bg-red-500 hover:bg-red-700">
                              Delete
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <span>Games</span>{" "}
                <div className="h-32 w-64 m-4 border overflow-scroll">
                  <ul>
                    {!!games.length &&
                      games.map((g, i) => {
                        return (
                          <li
                            key={i}
                            className="flex justify-between w-full p-4 border-b"
                          >
                            {i}
                            <Button className="bg-red-500 hover:bg-red-700">
                              Delete
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-[28px] font-bold text-grey-100 text-left mb-20 ml-10">
                Tournaments
              </h2>
              <Button
                className="bg-blue-600 hover:bg-blue-500"
                onClick={() => getTournaments()}
              >
                Get Tournaments
                {isLoading && <Loader2 className="animate-spin ml-2" />}
              </Button>
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px] text-center">Id</TableHead>
                  <TableHead className="text-left">Name</TableHead>
                  <TableHead className="text-left">Type</TableHead>
                  <TableHead className="text-left">Status</TableHead>
                  <TableHead className="text-center">Players</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {!!tournaments.length &&
                  tournaments.map((tournament, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className={cn(
                            "font-medium",
                            index % 2 !== 0 && "bg-grey-400"
                          )}
                        >
                          <div className="flex">
                            <div className="relative w-full">
                              <input
                                id="website-url"
                                type="text"
                                aria-describedby="helper-text-explanation"
                                className="bg-gray-50 rounded-s-lg border-gray-300 text-gray-500 dark:text-gray-400 text-sm border-s-0 block w-full p-0.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                value={tournament.id}
                                disabled
                              />
                            </div>
                            <TooltipProvider>
                              <Tooltip open={copied}>
                                <CopyToClipboard
                                  text={tournament.id}
                                  onCopy={handleCopy}
                                >
                                  <TooltipTrigger className="hover:text-grey-100 flex-shrink-0 inline-flex items-center py-1 px-1 text-sm font-medium text-center text-gray-500 bg-gray-50 rounded-e-lg hover:bg-grey-200  border-gray-300">
                                    <Copy className="w-4 h-4 " />
                                  </TooltipTrigger>
                                </CopyToClipboard>
                                <TooltipContent>
                                  <p>Successfully copied!</p>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        </TableCell>
                        <TableCell
                          className={cn(
                            "text-left",
                            index % 2 !== 0 && "bg-grey-400"
                          )}
                        >
                          {tournament.name}
                        </TableCell>
                        <TableCell
                          className={cn(
                            "text-left",
                            index % 2 !== 0 && "bg-grey-400"
                          )}
                        >
                          {tournament.type}
                        </TableCell>
                        <TableCell
                          className={cn(
                            "text-left",
                            index % 2 !== 0 && "bg-grey-400"
                          )}
                        >
                          {tournament.active ? (
                            <div className="flex items-center">
                              <div className="w-3 h-3 rounded-full bg-green-200 mr-2" />
                              active
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <div className="w-3 h-3 rounded-full bg-danger mr-2" />
                              inactive
                            </div>
                          )}
                        </TableCell>

                        <TableCell
                          className={cn(index % 2 !== 0 && "bg-grey-400")}
                        >
                          <div className="max-h-28 overflow-auto">
                            {tournament.players.map((player, index) => {
                              if (player.username) {
                                return (
                                  <div
                                    key={index}
                                    className="flex items-center"
                                  >
                                    {index + 1}.
                                    <div
                                      className={cn(
                                        "w-3 h-3 rounded-full mx-2",
                                        player.isDeleted
                                          ? "bg-black border"
                                          : convertPlayerStatus(player.status)
                                      )}
                                    />
                                    {player.username}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </TableCell>
                        <TableCell
                          className={cn(
                            "text-right",
                            index % 2 !== 0 && "bg-grey-400"
                          )}
                        >
                          <Button
                            className="bg-red-500 hover:bg-red-700"
                            onClick={() => deleteTournament(tournament.id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        )
      )}
    </div>
  );
};
