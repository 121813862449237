import { PlayerDisplayProps } from "./PlayerDisplay.types";
import { cn } from "src/lib/utils";

export const PlayerDisplay = ({
  playerToDisplay,
  white = false,
}: PlayerDisplayProps) => {
  return (
    <div className="flex justify-center w-full">
      <div
        className={cn(
          "flex items-center my-4",
          playerToDisplay === "Waiting..." ? "animate-pulse" : ""
        )}
      >
        <div
          className={cn(
            "w-2 h-2 md:w-5 md:h-5 rounded-full mr-2",
            white ? "bg-white" : "bg-black border border-grey-200"
          )}
        />
        <span className="max-w-24 break-normal text-xs md:text-base">
          {playerToDisplay}
        </span>
      </div>
    </div>
  );
};
