import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import "src/index.css";
import { z } from "zod";
import { Input } from "src/components/ui/input";
import { Button } from "src/components/ui/button";
import { Logo } from "src/app/common/Logo/Logo";
import { Loader2, SendHorizontal } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoute } from "src/routing/AppRoute.enum";
import { LoginProps } from "./Login.types";
import { usePlayerId } from "src/app/hooks/usePlayerId/usePlayerId";
import { usePlayer } from "src/app/hooks/usePlayer/usePlayer";
import { Player } from "src/app/context/playerContextController/PlayerContextController.types";
import { SocketEvent } from "src/app/types/types";
import { ChessLoader } from "src/app/components/Loaders/ChessLoader";
import Turnstile from "react-turnstile";
import magnusGif from "src/app/common/gifs/magnus-carlsen.gif";
import magnus2Gif from "src/app/common/gifs/magnus-carlsen2.gif";
import magnus3Gif from "src/app/common/gifs/magnus-carlsen3.gif";
import magnus4Gif from "src/app/common/gifs/magnus4.gif";
import hikaruGif from "src/app/common/gifs/hikaru.gif";
import hikaru2Gif from "src/app/common/gifs/hikaru2.gif";
import hikaru3Gif from "src/app/common/gifs/hikaru3.gif";
import catsChessGif from "src/app/common/gifs/cats-dogs.gif";
import pawelGif from "src/app/common/gifs/pawel-paweł.gif";

export const Login = ({ socket }: LoginProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingTournament, setLoadingTournament] = useState<boolean>(true);
  const [tournamentName, setTournamentName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [messageFromServer, setMessageFromServer] = useState<string>("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const navigate = useNavigate();
  const { setStoredPlayerId, playerId } = usePlayerId();
  const { updatePlayerInfo, updateUsername, player, resetPlayer } = usePlayer();
  const { pathname } = useLocation();

  const tournamentId = pathname.split("/")[1];

  const gifs = [
    magnusGif,
    magnus2Gif,
    magnus3Gif,
    magnus4Gif,
    hikaruGif,
    hikaru2Gif,
    catsChessGif,
    pawelGif,
    hikaru3Gif,
  ];

  const getRandomGif = (array: string[]) => {
    return array[Math.floor(Math.random() * array.length)];
  };

  const formSchema = z.object({
    username: z
      .string()
      .min(2, { message: "Username too short." })
      .max(15, { message: "Username too long." })
      .refine(
        (value) =>
          /^[a-zA-Z0-9\sŹÓŁĆŚŻŃĘŹółćśżńęàâäéèêëïîôöùûüÿÀÂÄÉÈÊËÏÎÔÖÙÛÜŸæçèìòùÄÖÑØßÆÇÈÌÒÙØß]+$/i.test(
            value
          ),
        {
          message: "Username must contain only letters and numbers.",
        }
      )
      .refine((value) => /[a-zA-Z]/.test(value), {
        message: "Username must contain at least one letter.",
      }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);

    if (process.env.REACT_APP_MODE !== "dev") {
      if (!recaptchaToken) {
        setErrorMessage("Please complete the captcha.");
        setIsLoading(false);

        return;
      }
    }

    const waitingRoomUrl = `/${tournamentId}/waiting`;
    if (playerId) {
      socket.emit(
        SocketEvent.USER_LOGIN,
        { username: values.username, id: playerId, tournamentId: tournamentId },
        (response: {
          success: boolean;
          message: string;
          user: Player;
          cause: "tournament" | "username";
        }) => {
          if (response.success) {
            updateUsername(values.username);
            navigate(waitingRoomUrl);
            updatePlayerInfo(response.user);
          } else if (response.cause === "username") {
            form.setError("username", {
              type: "manual",
              message: "Username is already taken.",
            });
          } else if (response.cause === "tournament") {
            setMessageFromServer("Tournament does not exist...");
            setTournamentName("");
          } else if (response.cause === "started") {
            setMessageFromServer("Tournament already started.");
            setTournamentName("");
          } else if (response.cause === "players-limit") {
            setMessageFromServer("Max players in tournament limit.");
            setTournamentName("");
          } else {
            setErrorMessage("Something went wrong. Refresh the page.");
          }
        }
      );
    } else {
      setErrorMessage("Something went wrong. Refresh the page.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    socket.on(SocketEvent.USER_ID, (id: string, callback: Function) => {
      setStoredPlayerId(id);
      callback(false);
    });

    if (player.username) {
      resetPlayer();
      navigate(AppRoute.home);
    }

    socket.emit(
      SocketEvent.ENTER_TOURNAMENT,
      tournamentId,
      ({
        tournamentName,
        isTournamentActive,
        timeControl,
      }: {
        tournamentName: string;
        isTournamentActive: boolean;
        timeControl: number;
      }) => {
        if (isTournamentActive) {
          setMessageFromServer(
            "This tournament already started. You are unable to join."
          );
          setLoadingTournament(false);
        } else {
          setTournamentName(tournamentName);
          setLoadingTournament(false);
          if (timeControl) {
            sessionStorage.setItem("time", timeControl.toString());
          }
        }
      }
    );

    return () => {
      socket.off(SocketEvent.USER_ID);
    };
  }, [player.username]);

  return (
    <div className="h-screen flex flex-col p-10 items-center">
      <Logo redirectToHome />
      <div className="mt-10">
        <div className="mb-10 text-center text-2xl">
          {loadingTournament ? (
            <ChessLoader />
          ) : tournamentName ? (
            <span>
              Welcome to <span className="font-bold">{tournamentName}</span>.
            </span>
          ) : (
            !messageFromServer && <span>Tournament does not exist...</span>
          )}
        </div>
        {tournamentName ? (
          <div>
            <h1 className="text-2xl font-bold text-grey-100 max-w-md mx-auto">
              Play as:
            </h1>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="mt-4 flex flex-col max-w-md mx-auto"
              >
                <div className="flex">
                  <FormField
                    control={form.control}
                    name="username"
                    disabled={isLoading}
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <Input
                            placeholder="Username"
                            className="md:w-96 h-10 p-2 text-center bg-foreground text-black placeholder:text-grey-200"
                            type="text"
                            id="username"
                            {...field}
                          />
                        </FormControl>
                        <div className="h-6 absolute">
                          <FormMessage className="text-danger" />
                        </div>
                      </FormItem>
                    )}
                  />

                  <Button
                    className="bg-foreground text-black ml-4 hover:bg-white"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Loader2 className="animate-spin" />
                    ) : (
                      <SendHorizontal />
                    )}
                  </Button>
                </div>
                <div className="text-danger mt-6 h-4">
                  {errorMessage && errorMessage}
                </div>
                <div className="mt-2">
                  <Turnstile
                    sitekey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY!}
                    onVerify={(token) => setRecaptchaToken(token)}
                  />
                </div>
              </form>
            </Form>
          </div>
        ) : (
          !loadingTournament && (
            <>
              <h1 className="text-2xl font-bold text-grey-100 mb-10 text-center">
                {messageFromServer}
              </h1>
              <img
                src={getRandomGif(gifs)}
                alt="chess-gif"
                className="h-96 mb-10 mx-auto"
              />
              <Button
                className="bg-foreground text-black hover:bg-white w-full"
                onClick={() => navigate(AppRoute.home)}
              >
                <span className="mr-2 text-lg">Go to home</span>{" "}
              </Button>
            </>
          )
        )}
      </div>
    </div>
  );
};
