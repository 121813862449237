import { useEffect, useRef } from "react";
import { convertMovesIntoNotation } from "src/app/utils/convertMovesToNotation";
import { NotationProps } from "./Notation.types";

export const Notation = ({ moves }: NotationProps) => {
  const notation = convertMovesIntoNotation(moves);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollLeft = scrollAreaRef.current.scrollWidth;
    }
  }, [notation]);

  return (
    <div
      className="flex items-center space-x-4 pb-2 scroll-smooth scrollbar-horizontal"
      ref={scrollAreaRef}
    >
      {notation.map((move, index) => (
        <figure key={index} className="shrink-0 bg-gray-700 px-4 rounded">
          <div className="overflow-hidden rounded-md">
            {index + 1}. {move}
          </div>
        </figure>
      ))}
    </div>
  );
};
