import { DefaultEventsMap } from "@socket.io/component-emitter";
import { Socket } from "socket.io-client";

export type TableSheetProps = {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
};

export type ConnectedClient = {
  id: string;
  username: string;
  points: number;
  status: PlayerStatus;
};

export enum PlayerStatus {
  NOT_STARTED = "notStarted",
  IN_GAME = "inGame",
  WAITING = "waiting",
  SPECTATOR = "spectator",
  DISCONNECTED = "disconnected",
}
