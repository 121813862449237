import { useEffect, useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "src/components/ui/sheet";
import { PlayerStatus, TableSheetProps } from "./Tablesheet.types";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "src/routing/AppRoute.enum";
import { Check, Menu, X } from "lucide-react";
import { ScoreTable } from "../ScoreTable/ScoreTable";
import { useAppDispatch, useAppSelector } from "src/app/redux/store";
import { setRoom } from "src/app/redux/features/GameSlice/GameSlice";
import {
  Color,
  SocketEvent,
  Tournament,
  TournamentType,
} from "src/app/types/types";
import { usePlayer } from "src/app/hooks/usePlayer/usePlayer";
import { Button } from "src/components/ui/button";
import { formatTime } from "src/app/utils/formatTime";
import {
  removeTimeFromSession,
  setBlackTime,
  setWhiteTime,
} from "src/app/redux/features/TimerSlice/TimerSlice";
import { setConnectedPlayers } from "src/app/redux/features/PlayersSlice/PlayersSlice";

export const TableSheet = ({ socket }: TableSheetProps) => {
  const [isLogouting, setIsLogouting] = useState<boolean>(false);
  const [tournamentInfo, setTournamentInfo] = useState<Tournament>();

  const navigate = useNavigate();
  const { player, resetPlayer } = usePlayer();

  const dispatch = useAppDispatch();

  const room = useAppSelector((state) => state.game.room);
  const playersInGame = useAppSelector((state) => state.game.players);
  const initialTimeInSeconds = useAppSelector(
    (state) => state.time.initialTimeInSeconds
  );
  const connectedPlayers = useAppSelector(
    (state) => state.players.connectedPlayers
  );

  const isPlayerAdmin = connectedPlayers.find(
    (p) => p.username === player.username
  )?.isAdmin;

  const arePlayersStillPlaying = connectedPlayers.some(
    (p) => p.status === PlayerStatus.IN_GAME
  );

  const username = player.username;

  const playerColor = playersInGame[1] === username ? Color.BLACK : Color.WHITE;

  const handleLogout = () => {
    socket.emit(SocketEvent.USER_LOGOUT, { username, playerColor, room });
    resetPlayer();
    dispatch(removeTimeFromSession());
    dispatch(setBlackTime(formatTime(initialTimeInSeconds)));
    dispatch(setWhiteTime(formatTime(initialTimeInSeconds)));
    dispatch(setConnectedPlayers([]));
    dispatch(setRoom(undefined));
    navigate(AppRoute.home);
  };

  const handleStartRound = () => {
    socket.emit(SocketEvent.START_ROUND, tournamentInfo?.id);
  };

  useEffect(() => {
    socket.emit(
      SocketEvent.GET_TOURNAMENT_INFO,
      player.username,
      (tournament: Tournament) => {
        setTournamentInfo(tournament);
      }
    );

    socket.on(SocketEvent.UPDATE_ONE_TOURNAMENT, (tournament: Tournament) => {
      dispatch(setConnectedPlayers(tournament.players));
      setTournamentInfo(tournament);
    });

    return () => {
      socket.off(SocketEvent.UPDATE_ONE_TOURNAMENT);
    };
  }, []);

  return (
    <Sheet>
      <SheetTrigger>
        <Menu />
      </SheetTrigger>
      <SheetContent className="max-w-96" aria-describedby="">
        <SheetHeader>
          <SheetTitle>
            <div className="w-full text-center font-normal">
              <span className="font-bold">{tournamentInfo?.name}</span>
            </div>
          </SheetTitle>
          <SheetTitle>
            <div className="w-full text-center font-normal">
              You play as <span className="font-bold">{username}</span>
            </div>
          </SheetTitle>

          <div>
            {isLogouting ? (
              <div className="flex flex-col items-center mt-4">
                <div className="text-sm mb-2">Withdraw from tournament?</div>
                <div className="flex">
                  <button
                    className="border-2 border-grey-200 p-2 rounded-tl rounded-bl hover:bg-danger cursor-pointer"
                    onClick={handleLogout}
                  >
                    <Check />
                  </button>
                  <button
                    className="border-2 border-grey-200 p-2 rounded-tr rounded-br hover:bg-grey-200 cursor-pointer"
                    onClick={() => setIsLogouting(false)}
                  >
                    <X />
                  </button>
                </div>
              </div>
            ) : (
              <Button
                type="button"
                className="w-full bg-red-500 hover:bg-red-700 mt-6"
                onClick={() => setIsLogouting(true)}
              >
                Logout
              </Button>
            )}

            <h1 className="w-full text-center text-xl mt-6">
              {tournamentInfo && tournamentInfo?.currentRound > 0 && (
                <>Round {tournamentInfo?.currentRound}</>
              )}
            </h1>
            {tournamentInfo?.type === TournamentType.SWISS &&
              isPlayerAdmin &&
              tournamentInfo?.id && (
                <Button
                  type="button"
                  className="w-full bg-blue-500 hover:bg-blue-900 mt-6"
                  onClick={handleStartRound}
                  disabled={arePlayersStillPlaying}
                >
                  {tournamentInfo?.currentRound !== 0
                    ? "Start next round"
                    : "Start first round"}
                </Button>
              )}

            <ScoreTable socket={socket} />
          </div>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};
