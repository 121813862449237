import { Outlet, useLocation } from "react-router-dom";
import { TableSheet } from "../components/TableSheet/TableSheet";
import { LayoutProps } from "./Layout.types";
import { AppRoute } from "src/routing/AppRoute.enum";
import { Logo } from "../common/Logo/Logo";

export const Layout = ({ socket }: LayoutProps) => {
  const { pathname } = useLocation();

  const pathCondition =
    pathname === AppRoute.home ||
    pathname.startsWith(AppRoute.tournament.split("/:id")[0]);

  const isTournamentEnd = pathname.includes("tournamentend");

  return (
    <div className="w-full h-screen flex flex-col">
      {pathCondition && (
        <div className="flex justify-between px-4 md:px-10 py-5">
          <div className="flex items-center justify-center">
            <Logo redirectToHome={isTournamentEnd} />
          </div>
          {!isTournamentEnd && <TableSheet socket={socket} />}
        </div>
      )}

      <main className="flex justify-center h-screen">
        <Outlet />
      </main>
    </div>
  );
};
