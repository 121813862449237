import { cn } from "src/lib/utils";
import { WinnersTableProps } from "./WinnersTable.types";

export const WinnersTable = ({ players }: WinnersTableProps) => {
  const sortedPlayers = [...players].sort((a, b) => b.points - a.points);

  return (
    <table className="w-full mt-6">
      <thead className="uppercase ">
        <tr className="rounded">
          <th scope="col" className="px-2 py-3 rounded-tl text-center">
            Players
          </th>
          <th scope="col" className="px-4 py-3 rounded-tr text-center">
            Points
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedPlayers.map((player, index) => {
          return (
            <tr
              className={cn("border border-grey-200", index === 0 && "text-xl")}
              key={index}
            >
              <th scope="row" className={cn("py-4 pl-2")}>
                <div className="flex items-center">
                  <div className={cn("w-3 h-3 rounded-full mr-2")} />
                  <div className="flex items-center">
                    <span className="text-left">
                      {index + 1}. {player.username} {index === 0 && "👑"}
                    </span>
                  </div>
                </div>
              </th>
              <td className="text-center">{player.points}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
