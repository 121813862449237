import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";

export const PlayerStatusGuideAccordion = () => {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1 border-none">
        <AccordionTrigger className="p-2 px-5 text-sm">
          Player Status Guide
        </AccordionTrigger>
        <AccordionContent>
          <table>
            <tbody>
              <tr>
                <th colSpan={2}>
                  <div className="flex items-center font-light px-2">
                    <div className="w-3 h-3 rounded-full bg-orange mr-2" />
                    <span className="text-xs lowercase">
                      - <span className="uppercase">C</span>hillin' around
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <th colSpan={2}>
                  <div className="flex items-center font-light px-2">
                    <div className="w-3 h-3 rounded-full bg-blue-100 mr-2" />
                    <span className="text-xs lowercase">
                      - <span className="uppercase">W</span>aiting for an
                      opponent
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <th colSpan={2}>
                  <div className="flex items-center font-light px-2">
                    <div className="w-3 h-3 rounded-full bg-green-200 mr-2" />
                    <span className="text-xs lowercase">
                      - <span className="uppercase">P</span>laying a game
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <th colSpan={2}>
                  <div className="flex items-center font-light px-2">
                    <div className="w-3 h-3 rounded-full bg-violet-100 mr-2" />
                    <span className="text-xs lowercase">
                      - <span className="uppercase">S</span>pectating a game
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <th colSpan={2}>
                  <div className="flex items-center font-light px-2">
                    <div className="w-3 h-3 rounded-full bg-danger mr-2" />
                    <span className="text-xs lowercase">
                      - <span className="uppercase">D</span>isconnected
                    </span>
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
