import { GameFinishReason } from "src/app/pages/tournament/Tournament.types";
import { resetGameState } from "../../redux/features/GameSlice/GameSlice";
import {
  setBlackTime,
  setWhiteTime,
} from "../../redux/features/TimerSlice/TimerSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { formatTime } from "../../utils/formatTime";
import { EndGameModalProps } from "./FinishGameModal.types";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/components/ui/alert-dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { Copy } from "lucide-react";
import { useState } from "react";
import { Color } from "src/app/types/types";

export const FinishGameModal = ({
  setIsFinishGameModalOpen,
  isFinishGameModalOpen,
}: EndGameModalProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const dispatch = useAppDispatch();

  const isGameFinished = useAppSelector((state) => state.game.isGameFinished);
  const pgn = useAppSelector((state) => state.game.pgn);

  const initialTimeInSeconds = useAppSelector(
    (state) => state.time.initialTimeInSeconds
  );

  const handleModalClose = () => {
    setIsFinishGameModalOpen(false);
    dispatch(setBlackTime(formatTime(initialTimeInSeconds)));
    dispatch(setWhiteTime(formatTime(initialTimeInSeconds)));
    dispatch(resetGameState());
  };

  const getModalInfo = (result: Color | "draw" | "bye" | undefined) => {
    switch (result) {
      case Color.WHITE:
        return "White won";
      case Color.BLACK:
        return "Black won";
      case "draw":
        return "Draw";
      case "bye":
        return "You received a bye because there is an odd number of players. You gain 1 free point.";
      default:
        return "";
    }
  };

  return (
    <AlertDialog open={isFinishGameModalOpen}>
      <AlertDialogContent className="rounded bg-transparentBackground">
        <AlertDialogHeader>
          <AlertDialogTitle>
            {isGameFinished?.result ? (
              <p>
                {getModalInfo(isGameFinished.result)}
                {isGameFinished.result !== "bye" && (
                  <span> by {isGameFinished?.reason}.</span>
                )}
              </p>
            ) : isGameFinished?.reason === GameFinishReason.tournamentFinish ? (
              <p>Tournament finished.</p>
            ) : (
              <p>Game ended because white did not make the first move.</p>
            )}
          </AlertDialogTitle>
          <AlertDialogDescription> </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex flex-col">
          {pgn.length > 0 &&
            isGameFinished?.reason !== GameFinishReason.firstMove &&
            pgn && (
              <TooltipProvider>
                <Tooltip open={copied}>
                  <CopyToClipboard text={pgn} onCopy={handleCopy}>
                    <TooltipTrigger className="flex justify-center items-center rounded-md h-10 text-blue-300 border border-transparent hover:border-blue-300 p-4">
                      Copy PGN <Copy className="w-4 h-4 ml-2" />
                    </TooltipTrigger>
                  </CopyToClipboard>
                  <TooltipContent className="bg-background">
                    <p>Successfully copied!</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}

          <AlertDialogCancel
            onClick={handleModalClose}
            className="hover:bg-[#343942] bg-transparentBackground"
          >
            Close
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
