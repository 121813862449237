import { useState, useEffect } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { ChessGameProps, Move } from "./ChessGame.types";
import { checkGameStatus } from "src/app/utils/checkGameStatus";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  setIsFirstMovePlayed,
  setIsGameFinished,
  setIsWhiteTurn,
  setMoves,
  setPgn,
  setRoom,
} from "src/app/redux/features/GameSlice/GameSlice";
import { FinishGameModal } from "../FinishGameModal/FinishGameModal";
import { INITIAL_FEN } from "src/app/constants/constants";
import { usePlayer } from "src/app/hooks/usePlayer/usePlayer";
import { SocketEvent } from "src/app/types/types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setConnectedPlayers } from "src/app/redux/features/PlayersSlice/PlayersSlice";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "src/routing/AppRoute.enum";
import { getResultComment } from "./functions/getResultComment";

export const ChessGame = ({
  socket,
  isPlayerWhite,
  setIsFinishGameModalOpen,
  isFinishGameModalOpen,
}: ChessGameProps) => {
  const [chess] = useState(new Chess());
  const [arePremovesAllowed, setArePremovesAllowed] = useState<boolean>(false);
  const [fen, setFen] = useState(chess.fen());
  const [error, setError] = useState<{ error: boolean; message: string }>({
    error: false,
    message: "",
  });

  const { player, setIsSpectator, resetPlayer } = usePlayer();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isGameFinished = useAppSelector((state) => state.game.isGameFinished);
  const room = useAppSelector((state) => state.game.room);
  const players = useAppSelector((state) => state.game.players);
  const isFirstMovePlayed = useAppSelector(
    (state) => state.game.isFirstMovePlayed
  );

  const isDrawOffered = useAppSelector((state) => state.game.isDrawOffered);

  const isFirstMove = chess.history().length === 0 && fen === INITIAL_FEN;

  const handleSuccessfulMove = (moveData: {
    from: string;
    to: string;
    promotion: string;
  }) => {
    setFen(chess.fen());
    const currentPosition = chess.fen();

    if (isFirstMove) {
      socket.emit(SocketEvent.GAME_STARTED, room);
      dispatch(setIsFirstMovePlayed(true));
    }

    const history = chess.history();
    dispatch(setMoves(history));

    socket.emit(SocketEvent.MOVE, moveData, history, room);
    socket.emit(SocketEvent.UPDATE_GAME, { room, currentPosition });

    const gameStatus = checkGameStatus(chess);

    if (gameStatus?.finished) {
      const { result, reason } = gameStatus;
      socket.emit(SocketEvent.GAME_END, { result, reason, room });
    }
  };

  const handleIllegalMoveError = () => {
    setError({ error: true, message: "Illegal move." });
    setTimeout(() => {
      setError({ error: false, message: "" });
    }, 850);
  };

  const onDrop = (source: string, target: string, piece: string): boolean => {
    setArePremovesAllowed(true);

    const isPlayerTurn =
      (players[0] === player.username && chess.turn() === "w") ||
      (players[1] === player.username && chess.turn() === "b");

    const isGameActive = !players.includes("Waiting...");

    if (!isPlayerTurn || !isGameActive) {
      return false;
    }

    try {
      setError({ error: false, message: "" });

      if (isDrawOffered) {
        socket.emit(SocketEvent.DECLINE_DRAW, room);
      }

      const moveData = {
        from: source,
        to: target,
        promotion: piece.slice(-1).toLowerCase(),
      };

      const move = chess.move(moveData);

      if (!move) {
        return false;
      }

      handleSuccessfulMove(moveData);
      return true;
    } catch (error) {
      handleIllegalMoveError();
      return false;
    }
  };

  useEffect(() => {
    socket.on(
      SocketEvent.PLAYER_MOVE,
      ({ move, history }: { move: Move; history: string[] }) => {
        if (isFirstMove) {
          dispatch(setIsFirstMovePlayed(true));
        }
        try {
          const playerMove = chess.move({
            from: move.from,
            to: move.to,
            promotion: move.promotion.slice(-1).toLowerCase(),
          });
          if (!playerMove) {
            return false;
          } else {
            setFen(chess.fen());
            dispatch(setMoves(history));
            return true;
          }
        } catch (error) {
          console.error(error);
        }
      }
    );

    socket.on(SocketEvent.FINISH_GAME, ({ result, reason }) => {
      dispatch(
        setIsGameFinished({
          finished: true,
          result: result,
          reason: reason,
        })
      );
      setIsSpectator(false);
    });

    socket.on(SocketEvent.TOURNAMENT_DELETED, () => {
      const deleteTournamentTimeout = setTimeout(() => {
        dispatch(setConnectedPlayers([]));
        resetPlayer();
        navigate(AppRoute.home);
      }, 5500);

      toast(
        "Tournament has been closed by admin. You're going to be redirected.",
        {
          onClose: () => {
            clearTimeout(deleteTournamentTimeout);
            dispatch(setConnectedPlayers([]));
            resetPlayer();
            navigate(AppRoute.home);
          },
        }
      );
    });

    return () => {
      socket.off(SocketEvent.PLAYER_MOVE);
      socket.off(SocketEvent.FINISH_GAME);
      socket.off(SocketEvent.TOURNAMENT_DELETED);
    };
  }, [isFirstMovePlayed, isFirstMove]);

  useEffect(() => {
    setFen(chess.fen());
  }, [chess]);

  useEffect(() => {
    if (chess.turn() === "w") {
      dispatch(setIsWhiteTurn(true));
    } else {
      dispatch(setIsWhiteTurn(false));
    }
  }, [chess.turn()]);

  useEffect(() => {
    if (isGameFinished?.finished && isGameFinished.reason) {
      chess.setComment(
        `${getResultComment(isGameFinished.result)} by ${isGameFinished.reason}`
      );
      dispatch(setPgn(chess.pgn()));
      setIsFinishGameModalOpen(true);
    }
  }, [isGameFinished?.finished]);

  useEffect(() => {
    if (!room) {
      chess.reset();
      setFen(INITIAL_FEN);
      setIsFinishGameModalOpen(false);
      dispatch(
        setIsGameFinished({
          finished: false,
          result: undefined,
          reason: undefined,
        })
      );
    }

    socket.on(
      SocketEvent.RECOVER_GAME,
      ({ fen, movesHistory, activeGameId }) => {
        if (isDrawOffered) {
          socket.emit(SocketEvent.DECLINE_DRAW, room);
        }

        if (fen !== INITIAL_FEN) {
          dispatch(setIsFirstMovePlayed(true));
        }
        setFen(fen);
        dispatch(setMoves(movesHistory));
        dispatch(setRoom(activeGameId));
        chess.loadPgn(movesHistory.join("\n"));
      }
    );

    return () => {
      socket.off(SocketEvent.RECOVER_GAME);
    };
  }, [room]);

  return (
    <div className="relative flex justify-center items-center h-full">
      <Chessboard
        position={fen}
        onPieceDrop={onDrop}
        arePremovesAllowed={arePremovesAllowed}
        boardOrientation={isPlayerWhite}
        arePiecesDraggable={!isGameFinished?.finished && !player.isSpectator}
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-danger font-bold bg-slate-50 rounded px-2 opacity-50 animate-shake">
        {error.error && <div className="animate-shake">{error.message}</div>}
      </div>
      <FinishGameModal
        setIsFinishGameModalOpen={setIsFinishGameModalOpen}
        isFinishGameModalOpen={isFinishGameModalOpen}
      />
      <ToastContainer theme="dark" pauseOnHover={false} />
    </div>
  );
};
