import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { ChessLoader } from "src/app/components/Loaders/ChessLoader";

export const ManagerAuth = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, []);

  return <ChessLoader/>;
};
