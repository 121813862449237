import { Copy } from "lucide-react";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";

export const CopyUrlContainer = ({ url }: { url: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <div className="w-full max-w-sm">
      <div className="mb-2 flex justify-between items-center">
        <label className="text-sm font-medium">
          <span>Invitation Link:</span>
          <link
            rel="alternate"
            href="atom.xml"
            type="application/atom+xml"
            title="Atom"
          />
        </label>
      </div>
      <div className="flex items-center">
        <div className="relative w-full">
          <input
            id="website-url"
            type="text"
            aria-describedby="helper-text-explanation"
            className="rounded-s-lg bg-gray-50 border border-e-0 border-gray-300 text-gray-500 dark:text-gray-400 text-sm border-s-0  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            value={url}
            disabled
          />
        </div>
        <TooltipProvider>
          <Tooltip open={copied}>
            <CopyToClipboard text={url} onCopy={handleCopy}>
              <TooltipTrigger className="hover:text-grey-100 flex-shrink-0 inline-flex items-center py-3 px-4 text-sm font-medium text-center text-gray-500 bg-gray-50 rounded-e-lg hover:bg-grey-200 border border-e-0 border-gray-300">
                <Copy className="w-4 h-4 " />
              </TooltipTrigger>
            </CopyToClipboard>
            <TooltipContent>
              <p>Successfully copied!</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <div
          id="tooltip-website-url"
          role="tooltip"
          className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
        >
          <span id="default-tooltip-message">Copy link</span>
          {copied && (
            <span id="success-tooltip-message" className="hidden">
              Copied!
            </span>
          )}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
  );
};
