import ReactDOM from "react-dom/client";
import "./index.css";
import { AppRoutes } from "./routing/AppRoutes";
import { AppProviders } from "./app/providers/AppProviders";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN!}
    clientId={process.env.REACT_APP_AUTH_CLIENT_ID!}
    authorizationParams={{
      redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    }}
  >
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  </Auth0Provider>
);
