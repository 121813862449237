import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import "src/index.css";
import { z } from "zod";
import { Input } from "src/components/ui/input";
import { Button } from "src/components/ui/button";
import { Logo } from "src/app/common/Logo/Logo";
import { Info, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "src/routing/AppRoute.enum";
import { LoginProps } from "./Login.types";
import { usePlayerId } from "src/app/hooks/usePlayerId/usePlayerId";
import { v4 } from "uuid";
import { Player } from "src/app/context/playerContextController/PlayerContextController.types";
import { usePlayer } from "src/app/hooks/usePlayer/usePlayer";
import { SocketEvent, TournamentType } from "src/app/types/types";
import { ToastContainer, toast } from "react-toastify";
import { Label } from "src/components/ui/label";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import Turnstile from "react-turnstile";
import { minPlayersForRounds } from "src/app/utils/minPlayersForRounds";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/components/ui/tooltip";

export const Create = ({ socket }: LoginProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isSwiss, setIsSwiss] = useState<boolean>(false);
  const [swissRounds, setSwissRounds] = useState<number>(3);
  const [isFFATooltipOpen, setIsFFATooltipOpen] = useState(false);
  const [isSwissTooltipOpen, setIsSwissTooltipOpen] = useState(false);

  const navigate = useNavigate();

  const { setStoredPlayerId, playerId } = usePlayerId();
  const { updatePlayerInfo, updateUsername, player, resetPlayer } = usePlayer();

  const formSchema = z.object({
    tournamentName: z
      .string()
      .min(2, { message: "Tournament name too short." })
      .max(40, { message: "Tournament name too long." })
      .refine((value) => /^[a-zA-Z0-9\s]+$/.test(value), {
        message: "Tournament name must contain only letters and numbers.",
      })
      .refine((value) => /[a-zA-Z]/.test(value), {
        message: "Tournament name must contain at least one letter.",
      }),
    username: z
      .string()
      .min(2, { message: "Username too short." })
      .max(16, { message: "Username too long." })
      .refine((value) => /^[a-zA-Z0-9\sŹÓŁĆŚŻŃĘŹółćśżńę]+$/i.test(value), {
        message: "Username must contain only letters and numbers.",
      })
      .refine((value) => /[a-zA-Z]/.test(value), {
        message: "Username must contain at least one letter.",
      }),
    time: z
      .string()
      .min(1, { message: "1 minute is minimum." })
      .max(30, { message: "30 minutes is maximum." })
      .refine(
        (value) => {
          const numberValue = Number(value);
          return !isNaN(numberValue) && numberValue >= 1 && numberValue <= 30;
        },
        {
          message: "Between 1 - 30 minutes allowed.",
        }
      ),
    increment: z
      .string()
      .min(0, { message: "0 seconds is minimum." })
      .max(30, { message: "30 seconds is maximum." })
      .refine(
        (value) => {
          const numberValue = Number(value);
          return !isNaN(numberValue) && numberValue >= 0 && numberValue <= 30;
        },
        {
          message: "Between 0 - 30 seconds allowed.",
        }
      ),
    type: z.string({ message: "Option must be selected." }),
    win: z
      .string()
      .min(1, {
        message: isSwiss ? "2 is minimum" : "1 is minimum.",
      })
      .max(isSwiss ? 4 : 50, {
        message: isSwiss ? "4 is maximum" : "50 is maximum.",
      })
      .refine(
        (value) => {
          const numberValue = Number(value);
          return !isNaN(numberValue) && numberValue >= 1 && numberValue <= 50;
        },
        {
          message: isSwiss
            ? "Between 2 - 4 rounds allowed."
            : "Between 1 - 50 points allowed.",
        }
      ),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tournamentName: "",
      username: "",
      time: "3",
      increment: "2",
      type: TournamentType.FFA,
      win: "3",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);

    if (process.env.REACT_APP_MODE !== "dev") {
      if (!recaptchaToken) {
        setErrorMessage("Please complete the captcha.");
        setIsLoading(false);

        return;
      }
    }

    const newUuid = v4();
    const waitingRoomUrl = `/${newUuid}/waiting`;

    if (playerId) {
      socket.emit(
        SocketEvent.CREATE_TOURNAMENT,
        {
          name: values.tournamentName,
          id: newUuid,
          username: values.username,
          time: values.time,
          increment: values.increment,
          type: values.type,
          win: values.win,
        },
        ({
          username: isUsernameTaken,
          tournamentName: isTournamentNameTaken,
        }: {
          username: boolean;
          tournamentName: boolean;
        }) => {
          if (!isUsernameTaken && !isTournamentNameTaken) {
            socket.emit(
              SocketEvent.USER_LOGIN,
              {
                username: values.username,
                id: playerId,
                tournamentId: newUuid,
              },
              (response: {
                success: boolean;
                message: string;
                user: Player;
              }) => {
                if (response.success) {
                  updateUsername(values.username);
                  navigate(waitingRoomUrl);
                  updatePlayerInfo(response.user);
                  sessionStorage.setItem("time", values.time.toString());
                } else {
                  form.setError("username", {
                    type: "manual",
                    message: "Username is already taken.",
                  });
                }
              }
            );
          } else if (isTournamentNameTaken) {
            form.setError("tournamentName", {
              type: "manual",
              message: "Tournament name is already taken.",
            });
          } else if (isUsernameTaken) {
            form.setError("username", {
              type: "manual",
              message: "Username is already taken.",
            });
          } else {
            toast("Something went wrong. Refresh the page.");
          }
        }
      );
    } else {
      setErrorMessage("Something went wrong. Refresh the page.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    socket.on(SocketEvent.USER_ID, (id: string, callback: Function) => {
      setStoredPlayerId(id);
      callback(false);
    });

    if (player.username) {
      resetPlayer();
      navigate(AppRoute.home);
    }

    return () => {
      socket.off(SocketEvent.USER_ID);
    };
  }, [player.username]);

  return (
    <div className="min-h-screen flex flex-col p-10 items-center">
      <Logo redirectToHome />
      <div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="mt-6 flex flex-col"
          >
            <div className="text-[28px] font-bold text-grey-100 ">
              Tournament name:
            </div>
            <FormField
              control={form.control}
              name="tournamentName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder="Gambit Arena Tournament"
                      className="md:w-96 h-10 p-2 text-center bg-foreground text-black placeholder:text-grey-200 text-lg md:text-2xl"
                      type="text"
                      id="tournamentName"
                      {...field}
                    />
                  </FormControl>
                  <div className="h-6 absolute">
                    <FormMessage className="text-danger" />
                  </div>
                </FormItem>
              )}
            />

            <div className="text-[28px] font-bold text-grey-100 mt-6">
              Play as:
            </div>
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder="Checkmate Master"
                      className="md:w-96 h-10 p-2 text-center bg-foreground text-black placeholder:text-grey-200 text-lg md:text-2xl"
                      type="text"
                      id="username"
                      {...field}
                    />
                  </FormControl>
                  <div className="h-6 absolute">
                    <FormMessage className="text-danger" />
                  </div>
                </FormItem>
              )}
            />
            <div className="flex items-center mt-6">
              <div className="text-[28px] font-bold text-grey-100 mr-6">
                Time:
              </div>
              <div className="flex items-center">
                <FormField
                  control={form.control}
                  name="time"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder="3"
                          className="md:w-16 h-10 p-2 text-center bg-foreground text-black placeholder:text-grey-200 text-3xl"
                          type="number"
                          id="time"
                          min={1}
                          max={30}
                          {...field}
                        />
                      </FormControl>
                      <div className="h-6 absolute">
                        <FormMessage className="text-danger" />
                      </div>
                    </FormItem>
                  )}
                />
                <div className="text-sm md:text-[20px] font-bold text-grey-100 ml-6">
                  minutes per player
                </div>
              </div>
            </div>
            <div className="flex items-center mt-6">
              <div className="text-[28px] font-bold text-grey-100 mr-6">
                Increment:
              </div>
              <div className="flex items-center">
                <FormField
                  control={form.control}
                  name="increment"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder="2"
                          className="md:w-16 h-10 p-2 text-center bg-foreground text-black placeholder:text-grey-200 text-3xl"
                          type="number"
                          id="increment"
                          min={0}
                          max={30}
                          {...field}
                        />
                      </FormControl>
                      <div className="h-6 absolute">
                        <FormMessage className="text-danger" />
                      </div>
                    </FormItem>
                  )}
                />
                <div className="text-sm md:text-[20px] font-bold text-grey-100 ml-6">
                  sec / move
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="text-[28px] font-bold text-grey-100 mr-6 text-center">
                Tournament type:
              </div>
              <Controller
                name="type"
                control={form.control}
                render={({ field }) => (
                  <RadioGroup
                    value={field.value}
                    onValueChange={(value) => {
                      field.onChange(value);
                      setIsSwiss(value === TournamentType.SWISS);
                      setSwissRounds(3);
                      form.setValue("win", "3");
                    }}
                    className="mt-2 flex justify-center"
                  >
                    <div className="flex items-center space-x-2 mr-10">
                      <RadioGroupItem
                        value={TournamentType.SWISS}
                        id={TournamentType.SWISS}
                      />
                      <Label htmlFor="option-one ">
                        <div className="flex items-center">
                          <span className="mr-2">Swiss</span>{" "}
                          <TooltipProvider>
                            <Tooltip open={isSwissTooltipOpen}>
                              <TooltipTrigger asChild>
                                <div
                                  onMouseEnter={() =>
                                    setIsSwissTooltipOpen(true)
                                  }
                                  onMouseLeave={() =>
                                    setIsSwissTooltipOpen(false)
                                  }
                                >
                                  <Info className="text-blue-600 w-5 hover:text-blue-800" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent className="bg-background max-w-96">
                                <p>
                                  The Swiss system pairs players with similar
                                  scores, ensuring they face opponents of
                                  similar strength, while trying to switch
                                  colors each round if possible.
                                  <br />
                                  <br />
                                  Good choice for larger chess tournaments.
                                </p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </Label>
                    </div>
                    <div className="flex items-center space-x-2 mr-10">
                      <RadioGroupItem
                        value={TournamentType.FFA}
                        id={TournamentType.FFA}
                      />
                      <Label htmlFor="option-one">
                        <div className="flex items-center">
                          <span className="mr-2">FFA</span>
                          <TooltipProvider>
                            <Tooltip open={isFFATooltipOpen}>
                              <TooltipTrigger asChild>
                                <div
                                  onMouseEnter={() => setIsFFATooltipOpen(true)}
                                  onMouseLeave={() =>
                                    setIsFFATooltipOpen(false)
                                  }
                                >
                                  <Info className="text-blue-600 w-5 hover:text-blue-800" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent className="bg-background max-w-96">
                                <p>
                                  The FFA system pairs the first two ready
                                  players without considering their score or
                                  strength, and doesn't take color into account.
                                  It simply tries to avoid pairing the same
                                  players twice if possible. <br />
                                  <br />
                                  Good choice for quick and smaller tournaments.
                                </p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </Label>
                    </div>
                    {/* <div className="flex items-center space-x-2 mr-10">
                      <RadioGroupItem
                        value={TournamentType.BRACKETS}
                        id={TournamentType.BRACKETS}
                      />
                      <Label htmlFor="option-one">Brackets</Label>
                    </div> */}
                  </RadioGroup>
                )}
              />
            </div>
            {isSwiss ? (
              <div className="flex flex-col text-2xl items-center mt-6 border-x rounded">
                <div className="flex items-center">
                  <FormField
                    control={form.control}
                    name="win"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            placeholder="3"
                            className="md:w-40 h-10 p-2 text-center bg-foreground text-black placeholder:text-grey-200 text-3xl"
                            type="number"
                            id="win"
                            max={4}
                            min={2}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              setSwissRounds(Number(e.target.value));
                            }}
                          />
                        </FormControl>
                        <div className="absolute">
                          <FormMessage className="text-danger" />
                        </div>
                      </FormItem>
                    )}
                  />
                  <div className="font-bold text-grey-100 ml-4">rounds.</div>
                </div>
                <div className="font-bold text-red-600 mt-4 text-lg">
                  Minimum participants:{" "}
                  <span className="text-2xl">
                    {minPlayersForRounds(swissRounds)}
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex flex-col text-xl md:text-2xl items-center mt-6 border-x rounded">
                <div className="font-bold text-grey-100">
                  First player to achieve
                </div>
                <div className="flex items-center">
                  <FormField
                    control={form.control}
                    name="win"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            placeholder="3"
                            className="md:w-40 h-10 p-2 text-center bg-foreground text-black placeholder:text-grey-200 text-3xl"
                            type="number"
                            id="win"
                            {...field}
                            max={50}
                            min={1}
                          />
                        </FormControl>
                        <div className="absolute">
                          <FormMessage className="text-danger mt-6" />
                        </div>
                      </FormItem>
                    )}
                  />
                  <div className="font-bold text-grey-100 ml-4">points</div>
                </div>
                <div className="font-bold text-grey-100">
                  wins the tournament.
                </div>
              </div>
            )}

            <div className="mt-10 flex justify-center">
              <Turnstile
                sitekey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY!}
                onVerify={(token) => setRecaptchaToken(token)}
              />
            </div>
            <Button
              className="bg-foreground text-black mt-6 hover:bg-white text-md"
              type="submit"
              disabled={isLoading}
            >
              Submit
              {isLoading && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
            </Button>
          </form>
        </Form>
      </div>
      <div className="text-danger mt-4">{errorMessage}</div>
      <ToastContainer theme="dark" pauseOnHover={false} />
    </div>
  );
};
