import { WinnersTable } from "src/app/components/WinnersTable/WinnersTable";
import { useLocation, useNavigate } from "react-router-dom";
import { Player } from "src/app/context/playerContextController/PlayerContextController.types";
import { useEffect } from "react";
import { AppRoute } from "src/routing/AppRoute.enum";

export const TournamentEnd = () => {
  const location = useLocation();
  const players: Player[] = location.state?.players;
  const tournamentName: string = location.state?.tournamentName;
  const navigate = useNavigate();

  useEffect(() => {
    if (!players) {
      navigate(AppRoute.home);
    }
  });

  return players ? (
    <div className="flex flex-col items-center">
      <h1 className="text-lg">{tournamentName} Scoreboard</h1>
      <WinnersTable players={players} />
    </div>
  ) : (
    <>No data</>
  );
};
