import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PlayersSliceState } from "./PlayersSlice.types";
import { Player } from "src/app/context/playerContextController/PlayerContextController.types";

const initialState: PlayersSliceState = {
  connectedPlayers: [],
};

export const PlayersSlice = createSlice({
  name: "PlayersSlice",
  initialState,
  reducers: {
    setConnectedPlayers: (state, action: PayloadAction<Player[]>) => {
      state.connectedPlayers = action.payload;
    },
  },
});

export default PlayersSlice.reducer;
export const { setConnectedPlayers } = PlayersSlice.actions;
