import { Chess } from "chess.js";
import {
  GameFinishReason,
  GameResult,
} from "../pages/tournament/Tournament.types";
import { Color } from "../types/types";

export const checkGameStatus = (chess: Chess): GameResult => {
  let moveColor = Color.WHITE;
  if (chess.turn() === "b") {
    moveColor = Color.BLACK;
  }

  if (chess.isCheckmate()) {
    if (moveColor === Color.BLACK) {
      return {
        finished: true,
        result: Color.WHITE,
        reason: GameFinishReason.checkmate,
      };
    } else {
      return {
        finished: true,
        result: Color.BLACK,
        reason: GameFinishReason.checkmate,
      };
    }
  } else if (chess.isDraw()) {
    if (chess.isThreefoldRepetition()) {
      return {
        finished: true,
        result: "draw",
        reason: GameFinishReason.threefold,
      };
    } else if (chess.isStalemate()) {
      return {
        finished: true,
        result: "draw",
        reason: GameFinishReason.stalemate,
      };
    } else if (chess.isInsufficientMaterial()) {
      return {
        finished: true,
        result: "draw",
        reason: GameFinishReason.material,
      };
    }
  }
};
