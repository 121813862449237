import king from "src/app/common/images/king.png";

export const ChessLoader = () => {
  return (
    <img
      src={king}
      className="inline-block w-5 animate-spin align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      role="status"
    />
  );
};
