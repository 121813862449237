import { Route, Routes } from "react-router-dom";
import { AppRoute } from "./AppRoute.enum";

import { Tournament } from "../app/pages/tournament/Tournament";
import { io } from "socket.io-client";
import { Layout } from "../app/layout/Layout";
import { WaitingRoom } from "../app/pages/waitingRoom/WaitingRoom";
import { Login } from "../app/pages/login/Login";
import { Create } from "../app/pages/create/Create";
import { Home } from "../app/pages/home/Home";
import { ManagerPanel } from "src/app/pages/managerPanel/ManagerPanel";
import { ManagerAuth } from "src/app/pages/managerAuth/ManagerAuth";
import { TournamentEnd } from "src/app/pages/tournamentEnd/TournamentEnd";

const socket = io(process.env.REACT_APP_BACKEND_URL as string);

export const AppRoutes = () => (
  <Routes>
    <Route path={AppRoute.tournament} element={<Layout socket={socket} />}>
      <Route index element={<Tournament socket={socket} />} />
    </Route>
    <Route path={AppRoute.tournament}>
      <Route
        path={AppRoute.waitingRoom}
        element={<WaitingRoom socket={socket} />}
      />
      <Route path={AppRoute.login} element={<Login socket={socket} />} />
      <Route path={AppRoute.tournamentEnd} element={<Layout socket={socket} />}>
        <Route path={AppRoute.tournamentEnd} element={<TournamentEnd />} />
      </Route>
    </Route>
    <Route path={AppRoute.create} element={<Create socket={socket} />} />
    <Route path={AppRoute.home} element={<Home socket={socket} />} />
    <Route
      path={AppRoute.managerPanel}
      element={<ManagerPanel socket={socket} />}
    />
    <Route path={AppRoute.managerAuth} element={<ManagerAuth />} />
  </Routes>
);
