import { useLocation, useNavigate } from "react-router-dom";
import { ScoreTable } from "src/app/components/ScoreTable/ScoreTable";
import { setConnectedPlayers } from "src/app/redux/features/PlayersSlice/PlayersSlice";
import { useAppDispatch, useAppSelector } from "src/app/redux/store";
import { WaitingRoomProps } from "./WaitingRoom.types";
import { CopyUrlContainer } from "src/app/components/CopyUrlContainer/CopyUrlContainer";
import { useEffect, useState } from "react";
import { usePlayer } from "src/app/hooks/usePlayer/usePlayer";
import { Logo } from "src/app/common/Logo/Logo";
import { usePlayerId } from "src/app/hooks/usePlayerId/usePlayerId";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { SocketEvent, Tournament, TournamentType } from "src/app/types/types";
import { ChessLoader } from "src/app/components/Loaders/ChessLoader";
import { Check, X } from "lucide-react";
import { AppRoute } from "src/routing/AppRoute.enum";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MAX_PLAYERS_IN_TOURNAMENT } from "src/app/constants/constants";
import { minPlayersForRounds } from "src/app/utils/minPlayersForRounds";

export const WaitingRoom = ({ socket }: WaitingRoomProps) => {
  const [isUsersListLoading, setIsUsersListLoading] = useState<boolean>(true);
  const [tournamentName, setTournamentName] = useState<string>("");
  const [tournamentType, setTournamentType] = useState<TournamentType>();
  const [timeControl, setTimeControl] = useState<number>();
  const [increment, setIncrement] = useState<number>();
  const [tournamentsRounds, setTournamentsRounds] = useState<number>(0);
  const [isDeletingTournament, setIsDeletingTournament] =
    useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isLeavingTournament, setIsLeavingTournament] =
    useState<boolean>(false);

  const { pathname } = useLocation();
  const { player, updatePlayerInfo, resetPlayer, removeUsername } = usePlayer();
  const { setStoredPlayerId } = usePlayerId();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const host = window.location.host;
  const tournamentId = pathname.split("/")[1];
  const sharableLink = `${process.env.REACT_APP_PROTOCOL}://${host}/${tournamentId}`;

  const connectedPlayers = useAppSelector(
    (state) => state.players.connectedPlayers
  );

  const activePlayers = connectedPlayers.filter(
    (player) => player.isDeleted === false
  );

  const startTournament = () => {
    socket.emit(SocketEvent.START_TOURNAMENT, tournamentId);
  };

  const confirmDeleteTournament = () => {
    socket.emit(SocketEvent.DELETE_TOURNAMENT, tournamentId);
  };

  const confirmLeaveTournament = () => {
    socket.emit(SocketEvent.LEAVE_TOURNAMENT, {
      tournamentId: tournamentId,
      username: player.username,
    });
    dispatch(setConnectedPlayers([]));
    resetPlayer();
    navigate(AppRoute.home);
  };

  const isAdmin = connectedPlayers.some(
    (p) => p.username === player.username && p.isAdmin
  );

  const minimumPlayerAmount =
    tournamentType === TournamentType.SWISS
      ? minPlayersForRounds(tournamentsRounds)
      : 2;

  useEffect(() => {
    socket.emit(
      SocketEvent.USER_ENTERED_LOBBY,
      tournamentId,
      (tournament: Tournament) => {
        if (tournament) {
          if (tournament.active) {
            navigate(`/${tournamentId}`);
          } else {
            setTournamentName(tournament.name);
            setTournamentType(tournament.type);
            setTimeControl(tournament.time);
            setIncrement(tournament.increment);
            setTournamentsRounds(tournament.win);
          }
        }
      }
    );

    if (player.username) {
      socket.emit(SocketEvent.USER_RECONNECT, player.username);
    } else {
      navigate(`/${tournamentId}/login`);
    }

    socket.on(SocketEvent.USER_ID, (id: string, callback: Function) => {
      setStoredPlayerId(id);
      callback(false);
    });

    socket.on(SocketEvent.RECOVER_PLAYER, (playerInfo) => {
      updatePlayerInfo(playerInfo);
    });

    socket.on(SocketEvent.USERS_LIST_UPDATE, (users) => {
      dispatch(setConnectedPlayers(users));
      setIsUsersListLoading(false);
    });

    socket.on(SocketEvent.TOURNAMENT_STARTED, (tournamentId) => {
      if (tournamentId) {
        navigate(`/${tournamentId}`);
      }
    });

    socket.on(SocketEvent.TOURNAMENT_DELETED, () => {
      setIsDeletingTournament(false);
      setIsDeleted(true);
      const deleteTournamentTimeout = setTimeout(() => {
        dispatch(setConnectedPlayers([]));
        resetPlayer();
        navigate(AppRoute.home);
      }, 5500);

      toast(
        "Tournament has been closed by admin. You're going to be redirected.",
        {
          onClose: () => {
            clearTimeout(deleteTournamentTimeout);
            dispatch(setConnectedPlayers([]));
            resetPlayer();
            navigate(AppRoute.home);
          },
        }
      );
    });

    if (player.username) {
      socket.emit(
        SocketEvent.CHECK_IS_PLAYER_IN_TOURNAMENT,
        player.username,
        (isPlayerInTournament: boolean) => {
          if (!isPlayerInTournament) {
            resetPlayer();
            removeUsername();
            navigate(AppRoute.home);
          }
        }
      );
    }

    return () => {
      socket.off(SocketEvent.USER_ID);
      socket.off(SocketEvent.RECOVER_PLAYER);
      socket.off(SocketEvent.USERS_LIST_UPDATE);
      socket.off(SocketEvent.TOURNAMENT_STARTED);
      socket.off(SocketEvent.TOURNAMENT_DELETED);
    };
  }, []);

  return (
    <div className="flex flex-col items-center w-80 md:w-96 p-10 md:py-10 px-0 h-full min-h-screen">
      <Logo />
      {!isUsersListLoading && (
        <>
          <div className="mt-10 text-center text-2xl">
            <span>
              Welcome to <span className="font-bold">{tournamentName}</span>.
            </span>
          </div>
          <div className="text-center text-lg">
            <span>
              Tournament type:{" "}
              <span className="font-bold">
                {tournamentType === TournamentType.FFA ? (
                  <span className="uppercase">{TournamentType.FFA}</span>
                ) : (
                  tournamentType
                )}
              </span>
            </span>
          </div>
          <div className="text-center text-lg">
            <span>
              Time control: <span className="font-bold">{timeControl}</span> min
              for player
            </span>
          </div>
          <div className="text-center text-lg">
            <span>
              Increment: <span className="font-bold">{increment}</span> seconds
              per move
            </span>
          </div>
          {tournamentType === TournamentType.SWISS ? (
            <div className="text-center text-lg">
              <span>
                Rounds: <span className="font-bold">{tournamentsRounds}</span>
              </span>
            </div>
          ) : (
            <div className="text-center text-lg">
              <span>
                First player to achieve{" "}
                <span className="font-bold">{tournamentsRounds} </span>points
                wins.
              </span>
            </div>
          )}

          <div className="w-full text-center font-normal my-6">
            You play as <span className="font-bold">{player.username}</span>
          </div>
          <CopyUrlContainer url={sharableLink} />
        </>
      )}

      {isUsersListLoading ? (
        <div className="mt-10">
          <ChessLoader />
        </div>
      ) : (
        <div className="w-full">
          <div className="text-center w-full mt-6">
            Players in the tournament:
          </div>
          <div className="text-center w-full text-2xl font-bold">
            {activePlayers.length}{" "}
            <span className="text-lg font-normal">
              / {MAX_PLAYERS_IN_TOURNAMENT}
            </span>
          </div>
          {isAdmin ? (
            <>
              {connectedPlayers.length < minimumPlayerAmount && (
                <div className="text-center text-red-500 mt-4">
                  There have to be at least {minimumPlayerAmount} players to
                  start the tournament.
                </div>
              )}
              <Button
                className="w-full bg-green-100 hover:bg-[#5ad82e] mt-6 text-white"
                style={{
                  opacity: connectedPlayers.length < 2 ? 0.7 : 1,
                  cursor: connectedPlayers.length < 2 ? "" : "pointer",
                }}
                onClick={startTournament}
                disabled={
                  connectedPlayers.length < minimumPlayerAmount || isDeleted
                }
              >
                Start tournament
              </Button>
              {isDeletingTournament ? (
                <div className="mt-6 text-center">
                  <div className="text-sm mb-2">
                    Are you sure you want to delete tournament and kick out all
                    the players?
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="border-2 border-grey-200 p-2 rounded-tl rounded-bl hover:bg-danger cursor-pointer"
                      onClick={confirmDeleteTournament}
                    >
                      <Check />
                    </button>
                    <button
                      className="border-2 border-grey-200 p-2 rounded-tr rounded-br hover:bg-grey-200 cursor-pointer"
                      onClick={() => setIsDeletingTournament(false)}
                    >
                      <X />
                    </button>
                  </div>
                </div>
              ) : (
                <Button
                  className="w-full mt-4 hover:bg-red-700 hover:text-white"
                  onClick={() => setIsDeletingTournament(true)}
                  variant="outline"
                  disabled={isDeleted}
                >
                  Delete tournament
                </Button>
              )}
            </>
          ) : (
            <div className=" w-full  mt-6">
              <div className="text-red-500 text-center">
                Waiting for
                <Badge variant="outline" className="ml-2 bg-green-100">
                  Admin
                </Badge>{" "}
                to start the tournament...
              </div>
              {isLeavingTournament ? (
                <div className="mt-6 text-center">
                  <div className="text-sm mb-2">
                    Are you sure you want to leave the tournament?
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="border-2 border-grey-200 p-2 rounded-tl rounded-bl hover:bg-danger cursor-pointer"
                      onClick={confirmLeaveTournament}
                    >
                      <Check />
                    </button>
                    <button
                      className="border-2 border-grey-200 p-2 rounded-tr rounded-br hover:bg-grey-200 cursor-pointer"
                      onClick={() => setIsLeavingTournament(false)}
                    >
                      <X />
                    </button>
                  </div>
                </div>
              ) : (
                <Button
                  className="w-full mt-4 hover:bg-red-700 hover:text-white"
                  onClick={() => setIsLeavingTournament(true)}
                  variant="outline"
                  disabled={isDeleted}
                >
                  Leave tournament
                </Button>
              )}
            </div>
          )}
          <ScoreTable socket={socket} />
        </div>
      )}
      <ToastContainer theme="dark" pauseOnHover={false} />
    </div>
  );
};
