import { PlayerStatus } from "../components/TableSheet/Tablesheet.types";

export const convertPlayerStatus = (status: string) => {
  switch (status) {
    case PlayerStatus.IN_GAME:
      return "bg-green-200";
    case PlayerStatus.WAITING:
      return "bg-blue-100";
    case PlayerStatus.NOT_STARTED:
      return "bg-orange";
    case PlayerStatus.SPECTATOR:
      return "bg-violet-100";
    case PlayerStatus.DISCONNECTED:
      return "bg-danger";
  }
};
