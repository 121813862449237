import { useState } from "react";
import { PlayerContextType } from "../playerContext/PlayerContext.types";
import { Player, PlayerContextProps } from "./PlayerContextController.types";
import { PlayerContext, defaultValue } from "../playerContext/PlayerContext";
import { PlayerStatus } from "src/app/components/TableSheet/Tablesheet.types";

export const PlayerContextController = ({ children }: PlayerContextProps) => {
  const [player, setPlayer] = useState<Player>(defaultValue.player);

  const initialPlayer = {
    id: null,
    username: null,
    points: 0,
    status: PlayerStatus.NOT_STARTED,
    isAdmin: false,
    isSpectator: false,
    isDeleted: false,
  };

  const updatePlayerInfo = (newPlayerInfo: Player) => {
    setPlayer(newPlayerInfo);
  };

  const updateId = (id: string) => {
    setPlayer((prevPlayer) => ({ ...prevPlayer, id: id }));
  };

  const updateUsername = (newUsername: string) => {
    setPlayer((prevPlayer) => ({ ...prevPlayer, username: newUsername }));
    sessionStorage.setItem("username", newUsername);
  };

  const removeUsername = () => {
    setPlayer((prevPlayer) => ({ ...prevPlayer, username: null }));
    sessionStorage.removeItem("username");
  };

  const updatePlayerStatus = (playerStatus: PlayerStatus) => {
    setPlayer((prevPlayer) => ({ ...prevPlayer, status: playerStatus }));
  };

  const setIsSpectator = (isSpectator: boolean) => {
    setPlayer((prevPlayer) => ({ ...prevPlayer, isSpectator: isSpectator }));
  };

  const resetPlayer = () => {
    setPlayer(initialPlayer);
    sessionStorage.removeItem("time");
  };

  const contextValue: PlayerContextType = {
    player,
    updateId,
    updatePlayerInfo,
    updateUsername,
    removeUsername,
    updatePlayerStatus,
    setIsSpectator,
    resetPlayer,
  };

  return (
    <PlayerContext.Provider value={contextValue}>
      {children}
    </PlayerContext.Provider>
  );
};
