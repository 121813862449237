import { Eye } from "lucide-react";
import { Badge } from "src/components/ui/badge";
import { cn } from "src/lib/utils";
import { useAppDispatch, useAppSelector } from "src/app/redux/store";
import { PlayerStatusGuideAccordion } from "../TableSheet/PlayerStatusGuide";
import { PlayerStatus } from "../TableSheet/Tablesheet.types";
import { ScoreTableProps } from "./ScoreTable.types";
import { usePlayer } from "src/app/hooks/usePlayer/usePlayer";
import {
  setPlayers,
  setSpectator,
} from "src/app/redux/features/GameSlice/GameSlice";
import { SocketEvent } from "src/app/types/types";
import { convertPlayerStatus } from "src/app/utils/convertPlayerStatus";

export const ScoreTable = ({ socket }: ScoreTableProps) => {
  const {
    player: playerUser,
    setIsSpectator,
    updatePlayerStatus,
  } = usePlayer();

  const dispatch = useAppDispatch();

  const connectedPlayers = useAppSelector(
    (state) => state.players.connectedPlayers
  );

  const sortedPlayers = [...connectedPlayers].sort(
    (a, b) => b.points - a.points
  );

  const handleSpectate = (selectedPlayer: string, selectingPlayer: string) => {
    socket.emit(
      SocketEvent.SPECTATOR_JOIN,
      { selectedPlayer, selectingPlayer },
      (players: string[]) => {
        dispatch(setPlayers(players));
      }
    );
    dispatch(setSpectator(selectingPlayer));
    setIsSpectator(true);
    updatePlayerStatus(PlayerStatus.SPECTATOR);
  };

  return (
    <table className="w-full mt-6">
      <thead className="uppercase bg-grey-200">
        <tr className="rounded border-b">
          <th scope="col" className="px-2 py-3 rounded-tl text-center">
            Players
          </th>
          <th scope="col" className="px-4 py-3 rounded-tr text-center">
            Points
          </th>
        </tr>
        <tr className="border-none">
          <th colSpan={3}>
            <PlayerStatusGuideAccordion />
          </th>
        </tr>
      </thead>
      <tbody className="rounded-lg">
        {sortedPlayers.map((player, id) => {
          const isLastChild = id === sortedPlayers.length - 1;

          return (
            <tr
              className={cn(
                "bg-grey-200",
                isLastChild ? "border-none" : "border-b"
              )}
              key={id}
            >
              <th
                scope="row"
                className={cn(
                  "py-4 pl-2",
                  isLastChild && "rounded-bl",
                  player.isDeleted && "bg-grey-300 opacity-70"
                )}
              >
                <div className="flex items-center">
                  <div
                    className={cn(
                      "w-3 h-3 rounded-full mr-2",
                      player.isDeleted
                        ? convertPlayerStatus(PlayerStatus.DISCONNECTED)
                        : convertPlayerStatus(player.status)
                    )}
                  />
                  <div className="flex items-center">
                    <span className="text-left break-all">{player.username}</span>
                    {player.isAdmin && (
                      <Badge variant="outline" className="ml-2 bg-green-100">
                        Admin
                      </Badge>
                    )}
                    {player.status === PlayerStatus.IN_GAME &&
                      playerUser.status === PlayerStatus.NOT_STARTED && (
                        <Eye
                          onClick={() => {
                            if (playerUser.username && player.username) {
                              handleSpectate(
                                player.username,
                                playerUser.username
                              );
                            }
                          }}
                          className="hover:text-orange hover:cursor-pointer ml-2"
                        />
                      )}
                  </div>
                </div>
              </th>
              <td
                className={cn(
                  `text-center ${isLastChild ? "border-none rounded-br" : ""}`,
                  player.isDeleted && "bg-grey-300 opacity-60"
                )}
              >
                {player.points}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
